/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import axios from 'axios';
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import Load from "../../examples/Load";

// Data
import { url_serversq } from "../../context/var";

// Dashboard components
import Projects from "../../layouts/products/Projects";
import ListP from "./ListP";

function Product() {

  const [das, setDas] = useState(() => {
    return false
  });


  if (!das) {
    //console.log(url_serversq + "/getdas.php");
    let s_key = localStorage.getItem('s_key');
    let locale = localStorage.getItem("lang") || 'ru';
    axios.post(url_serversq + "/getdas.php", {
      s_key: s_key, locale: locale
    }).then(res => {
      // localStorage.clear();
      if (res.data != false) {
         console.log(res.data);
        setDas(res.data);
      } else {
        alert("Server communication error");
        localStorage.clear();
        window.location.href = "/"
      }
    }).catch(() => {
      alert("Server communication error");
      localStorage.clear();
      window.location.href = "/"
    })
  }

  if (das) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox >
          <ListP das={das.packs} bal={das.bonus} packs={das.products} group={das.groupp} actionUPD={setDas} />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Product;
