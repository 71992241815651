/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../dashboard/components/Projects/data";
import { url_serversq, number_format, getPool } from "../../../../context/var";
import MDButton from "../../../../components/MDButton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, Paper, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useMaterialUIController } from "../../../../context";
import { t, jt } from 'ttag';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import MDInput from "../../../../components/MDInput";
import { margin } from "@mui/system";


function Discount({ das, setDas }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  var stwd = darkMode ? { color: '#fff' } : { color: '#212121' }


  return (
    <Card >
      <Grid container p={0} spacing={2}>
        <Grid item xs={1} md={3} >

        </Grid>
        <Grid item xs={10} md={6}>
          <MDTypography variant="h4" mt={4} textAlign="center">{t`Дисконтная программа ISE Bonus `}</MDTypography>
          <Card style={{ marginTop: "15px", marginBottom: "15px", padding: "25px" }} >

            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{t`Сумма бонусов`}</MDTypography></Grid>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{t`Скидка`}</MDTypography></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">20$ - 100$</MDTypography></Grid>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{25 - das.disc * (25 / 100)}%</MDTypography></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">101$ - 600$</MDTypography></Grid>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{50 - das.disc * (50 / 100)}%</MDTypography></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">601$ - 2000$</MDTypography></Grid>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{75 - das.disc * (75 / 100)}%</MDTypography></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center"> {t`>`} 2001$ </MDTypography></Grid>
              <Grid item xs={6} ><MDTypography variant="h6" textAlign="center">{(100 - das.disc * (100 / 100)) == 100 ? '50$' : (100 - das.disc * (100 / 100)) + '%'}</MDTypography></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={12} ><MDTypography variant="h6" textAlign="center"> {t`* сумма приобретенных бонусов не должна превышать 20% от депозита в кабинете`} </MDTypography></Grid>
            </Grid>
          </Card>
        </Grid >

      </Grid >


    </Card >

  );


}

export default Discount;
