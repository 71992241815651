/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import MasterCard from "../../examples/Cards/MasterCard";
import DefaultInfoCard from "../../examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Withdrowal from "../../layouts/billing/components/Withdrowal";
import WithdrowalHistory from "../../layouts/billing/components/WithdrowalHistory";
import BillingInformation from "../../layouts/billing/components/BillingInformation";
import Transactions from "../../layouts/billing/components/Transactions";
import Requests from "./components/Requests";
import axios from 'axios';
import { useState } from "react";
import Load from "../../examples/Load";
import { url_serversq,number_format } from "../../context/var";
import { t, jt } from 'ttag';
import { Card, Icon } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Deposit from "./components/Deposit";
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';

function Billing() {
  const [das, setDas] = useState(() => {
    return false
  });
  const [oper, setOper] = useState(() => { return false });

  if (!das) {

    let locale = localStorage.getItem("lang") || 'ru';
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getbill.php", {
      s_key: s_key, locale: locale,
    }).then(res => {
      if (res.data != false) {
        setDas(res.data);
      } else {
        alert(t`Ошибка связи с сервером`);
        window.location.href = "/"
      }
    }).catch(() => {
      alert(t`Ошибка связи с сервером`);
      window.location.href = "/"
    })
  }

  if (das) {
    let bonus = "$" + number_format(parseFloat(das.bonus));
    let future = "$" + number_format(parseFloat(das.future));
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={2}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Card sx={{ height: "100%" }}>
                  <Grid container spacing={3} sx={{ alignItems: 'center', justifyContent: 'center', height: "100%" }}>
                    {oper == 2 ? <Grid item xs={12}><Withdrowal das={das} setDas={setDas} setOper={setOper} /></Grid> : null}
                    {oper == 1 ? <Grid item xs={12}><Deposit das={das} setDas={setDas} setOper={setOper} /></Grid> : null}
                    {oper == 0 ?
                      <>
                        <Grid item xs={6} md={4}>
                          <Card onClick={() => setOper(1)} sx={{ cursor: "pointer", border: "1px solid #ff9800", }}>
                            <MDBox p={2} mx={3} display="flex" justifyContent="center" >
                              <MDBox
                                display='flex' justifyContent="center" alignItems="center" width="4rem" height="4rem" shadow="md" borderRadius="lg"
                                sx={{ backgroundColor: '#ffa207', backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}
                              >
                                <MDTypography> <InputIcon fontSize="large" /></MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                {t`Пополнение`}
                              </MDTypography>
                            </MDBox>
                          </Card>
                        </Grid>
                        <Grid item xs={6} md={4} >
                          <Card onClick={() => setOper(2)} sx={{ cursor: "pointer", border: "1px solid #ff9800", }}>
                            <MDBox p={2} mx={3} display="flex" justifyContent="center" >
                              <MDBox
                                display='flex' justifyContent="center" alignItems="center" width="4rem" height="4rem" shadow="md" borderRadius="lg"
                                sx={{ backgroundColor: '#ffa207', backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}
                              >
                                <MDTypography><OutputIcon fontSize="large" width="100%" /></MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                {t`Вывод`}
                              </MDTypography>
                            </MDBox>
                          </Card>
                        </Grid>
                      </> : null}
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <DefaultInfoCard
                      icon="account_balance"
                      title={t`Доступный баланс`}
                      description=""
                      value={bonus}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} xl={5}>
                <Requests das={das} setDas={setDas} />
              </Grid>
              <Grid item xs={12} xl={7}>
                <Transactions das={das} />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>

            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Billing;
