/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Icon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { t } from "ttag";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 React base styles

export default function data({ das, btnDel, setId, setOpen }) {



    const Company = ({ name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            {/* <MDAvatar src={image} name={name} size="sm" /> */}
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1} >
                {name}
            </MDTypography>
        </MDBox>
    );


    var rw = [];

    das.req.forEach(element => {
        rw.push({
            dt: (<MDTypography variant="caption" color="text" fontWeight="medium">
                {element.dt}
            </MDTypography>
            ),
            ptype: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    {element.ptype}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    ${element.amount}
                </MDTypography>
            ),
            pstatus: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    {element.pstatus}
                </MDTypography>
            ),
            action: (element.pstatus == "New" ?
                <MDTypography component={Link} onClick={() => { setId(element.id); setOpen(true); }} variant="body2" color="secondary">
                    <Tooltip title={"Удалить"} placement="top">
                        <Icon>delete</Icon>
                    </Tooltip>
                </MDTypography> : null),
        });
    });


    return {
        columns: [
            { Header: "", accessor: "action", align: "center" },
            { Header: t`Сумма`, accessor: "amount", align: "center" },
            { Header: t`Тип`, accessor: "ptype", align: "left" },
            { Header: t`Дата`, accessor: "dt", align: "left" },
            { Header: t`Статус`, accessor: "pstatus", align: "center" },

        ],
        rows: rw

    };
}
