/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";
import Icon from "@mui/material/Icon";

// Images
import usdtLogo from "../../../../assets/images/logos/usdtlogo.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../../context";
import { Box, Button, IconButton, MenuItem, MobileStepper, Select, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import axios from 'axios';
import { url_serversq } from "../../../../context/var";
import Load from "../../../../examples/Load";
import { display, height } from "@mui/system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { t, jt } from 'ttag';
import MDSnackbar from "../../../../components/MDSnackbar";

function Deposit({ das, setDas, setOper }) {
  const [isload, setLoad] = useState(() => { return false });
  const [isload1, setLoad1] = useState(() => { return false });
  const [controller] = useMaterialUIController();
  const [activeStep, setActiveStep] = useState(() => { return 0 });

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [infoSB, setInfoSB] = useState(false);
  const wallLink = 'TThjqo3h7gw9WwN5jxuNYu6tzx38HGNrAm'
  const copyLink = event => {
    event.persist()

    navigator.clipboard.writeText(wallLink)
      .then(() => {

      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    openInfoSB();
  }
  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t`Информация`}
      content={t`Адрес скопирован в буфер обмена`}
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  const { transparentNavbar, darkMode } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar && darkMode) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const [req, setReq] = useState(() => {
    return {
      ptype: 1,
      wallet: "",
      amount: 1,
      eKey: "",
      eKeye: false,
    }
  })
  const changeInputeKey = event => {
    //console.log(1);
    event.persist()
    setReq(prev => { return { ...prev, eKey: event.target.value, } });

  }
  const handleChange = (event) => {
    //console.log(2);
    var vol = null;
    if (event.target.type == "number")
      vol = event.target.value;
    else
      vol = event.target.value;
    setReq(prev => {
      return { ...prev, [event.target.name]: vol, }
    });
  };

  const handleSave = (event) => {
    //console.log(3);
    event.preventDefault();

    if (req.wallet == "" || req.amount == 0) {
      alert(t`Пожалуйста заполните все данные`);
    } else {
      let s_key = localStorage.getItem("s_key");
      let reqa = { ...req, ptype: 2, s_key: s_key }
      setLoad(true);
      axios.post(url_serversq + "/addreq.php", reqa).then(res => {

        //console.log(res.data);

        if (res.data !== false) {
          alert(res.data);
          setLoad1(false);
          setDas(false);
          setOper(0)
        } else {
          alert(t`Ошибка добавления, неправильно введенные данные`);
          setLoad1(false);
          setDas(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad1(false);
        setDas(false);
      })
    }
  };

  if (isload)
    return (<Load />);

  const steps = [

    {
      label: t`Параметры`,
      description: (
        <MDBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>

              <MDBox
                borderRadius="lg"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox display="flex" flexDirection="column" py={1} pr={2}>
                  <MDTypography variant="button" width='50%' fontWeight="regular" mr={2}>
                    {t`Счет для пополнения USDT TRC-20:`} &nbsp;&nbsp;
                  </MDTypography>
                  <MDTypography type="text" variant="standard" maxWidth="100%"  fontWeight="bold" onClick={copyLink} >
                    {wallLink}
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                       aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={copyLink}
                    >
                      <Icon sx={iconsStyle}>copy</Icon>
                    </IconButton>
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2}>
                  <MDTypography variant="button" width='50%' fontWeight="regular" mr={2}>
                    {t`TxId транзакция:`} &nbsp;&nbsp;
                  </MDTypography>
                  <MDInput type="text" variant="standard" fullWidth fontWeight="bold" name="wallet" value={req.wallet} onChange={handleChange} />
                </MDBox>
                <MDBox display="flex" py={1} pr={2}>
                  <MDTypography variant="caption" width='100%' fontWeight="regular" mr={2}>
                    {t`* Для зачисления в транзакции должно пройти более 20 подтверждений`} &nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          {renderInfoSB}
        </MDBox>
      ),
    },
    {
      label: t`Подтверждение`,
      description: (
        <MDBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MDBox key={"key"} >
                <MDBox p={4} key={"key2"} display="flex" justifyContent="center">
                  <MDTypography variant="button" fontWeight="bold" >
                    {t`Сумма к зачислению:`} &nbsp;&nbsp;
                  </MDTypography>
                  <MDInput type="text" variant="standard" value={req.amount} /> &nbsp;&nbsp;
                </MDBox>

                <MDBox p={4} key={"key3"} display="flex" justifyContent="center">
                  {isload1 ? <Load /> :
                    <MDButton variant="gradient" color="success" onClick={handleSave}>
                      {t`Подтвердить`}
                    </MDButton>}
                </MDBox>

              </MDBox>
            </Grid>
          </Grid>
        </MDBox >
      ),
    },
  ];

  const handleNext = () => {

    if (activeStep === steps.length - 1) {
      setReq(prev => {
        return { ...prev, eKeye: false, }
      });
      setActiveStep(0);
    } else if (activeStep === 0) {
      if (!req.wallet)
        alert(t`Введите TxId транзакции`);
      else {
        setLoad(true);
        let s_key = localStorage.getItem("s_key");
        let reqa = { txId: req.wallet, s_key: s_key }
        axios.post(url_serversq + "/chektxidn.php", reqa).then(res => {
          //console.log(res.data);
          if (res.data !== false) {

            let rres = parseFloat(res.data.deposit)
            if (rres == 0) {
              alert(t`Сумма транзакции 0`);
              setLoad(false);
            } else if (rres == -1) {
              alert(t`Адресс получателя не совпадает`);
              setLoad(false);
            } else if (rres == -2) {
              alert(t`Количество подтверждений меньше 20, попробуйте позже`);
              setLoad(false);
            } else {
              setReq(prev => { return { ...prev, amount: res.data.deposit, } });
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setLoad(false);
            }
          } else {
            alert(t`Ошибка при проверке TxId транзакции`);
            setLoad(false);
          }
        }).catch(() => {
          alert("An error occurred on the server");
          setLoad(false);
        })
      }
    } else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOper(0)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (<Card style={{ justifyContent: 'space-between' }}>
    <div style={{ minHeight: 350 }}>
      <MDBox pt={2} px={2} >
        <MDTypography variant="h6" fontWeight="medium" align="center">
          {t`Создание заявки на пополнение баланса`}
        </MDTypography>
      </MDBox>
      <MDBox style={{
        minHeight: '90%', display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}>
        {steps[activeStep].description}
      </MDBox>
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{ flexGrow: 1 }}
        nextButton={
          <MDButton variant="gradient" color="info" size="small" onClick={handleNext}>
            {activeStep === steps.length - 1 ? t`Заново` : t`Далее`}
            <KeyboardArrowRight />
          </MDButton>
        }
        backButton={
          <MDButton variant="gradient" color="info" size="small" onClick={handleBack} >
            <KeyboardArrowLeft />
            {t`Назад`}
          </MDButton>
        }
      />
    </div>

  </Card>
  );


  var ek = null;
  var bt = <MDButton variant="gradient" color="dark" onClick={handleSave}>
    Создать заявку
  </MDButton>;
  if (isload1)
    bt = <Load />;
  if (req.eKey !== false)
    ek =
      <MDBox key={"key"} display="flex">
        <MDTypography variant="button" fontWeight="bold" >
          Email key: &nbsp;&nbsp;
        </MDTypography>
        <MDInput type="text" variant="standard" fullWidth value={req.eKey} onChange={changeInputeKey} /> &nbsp;&nbsp;
      </MDBox>
      ;

  return (
    <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" justifyContent="space-between" alignItems="left">
          <MDTypography variant="h6" fontWeight="medium">
            Тип заявки
          </MDTypography>

          <Select
            value={req.ptype}
            label="Тип заявки"
            name="ptype"
            onChange={handleChange}

          >
            <MenuItem key={"s1"} value={1}>Вывод</MenuItem>
            <MenuItem key={"s2"} value={2}>Пополнение</MenuItem>
          </Select>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="left">
          {ek}
          {bt}
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>

            <MDBox
              borderRadius="lg"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox >
                <MDTypography variant="button" fontWeight="bold" mr={2}>
                  Сумма: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="number" variant="standard" fontWeight="bold" name="amount" value={req.amount} onChange={handleChange} />
              </MDBox>
              <MDBox display="flex">
                <MDBox component="img" src={usdtLogo} alt="USDT Wallet" width="40px" mr={2} />
                <MDInput type="text" variant="standard" fullWidth name="wallet" value={req.wallet} onChange={handleChange} />
              </MDBox>
              <MDBox display="flex" flexDirection="column" mt={3}>
                <MDTypography variant="caption" color="text" fontWeight="regular">
                  Комиссия за вывод 1 USDT
                </MDTypography>
                <MDTypography variant="caption" color="text" fontWeight="regular">
                  Заявки обрабатываются в течении 24 часов.
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

export default Deposit;
