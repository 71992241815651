/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../../layouts/dashboard/components/Projects/data";
import { url_serversq } from "../../../../context/var";
import MDButton from "../../../../components/MDButton";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Switch, TextField, Tooltip } from "@mui/material";
import { useMaterialUIController } from "../../../../context";
import { t, jt } from 'ttag';

function Projects({ bal, das, packs, actionUPD }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const handleSwitch = (event) => {
    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, id: event.target.value }
    //setLoad(true);
    let ur = event.target.checked ? "/activpack.php" : "/deactivpack.php";
    axios.post(url_serversq + ur, req).then(res => {


      if (res.data !== false) {
        //alert(res.data);
        actionUPD(false);
        // setLoad(false);
      } else {
        //setLoad(false);
        alert(t`Ошибка`);
      }
    }).catch(() => {
      //setLoad(false);
      alert("An error occurred on the server");
    })
  };


  /* useEffect(() => {
    if (!prop.api_key) {
      setActiveStep(0);
      setPtype(t.id)
    }
  }); */

  const [open, setOpen] = useState(() => { return false });
  const [idd, setId] = useState(() => { return false });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOK = () => {
    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, id: idd }

    let ur = "/droppack.php";
    axios.post(url_serversq + ur, req).then(res => {
      if (res.data !== false) {
        actionUPD(false);
      } else {
        alert(t`Ошибка`);
      }
    }).catch(() => {
      alert("An error occurred on the server");
    })
    setOpen(false);
  };

  const { columns, rows } = data({ das, handleSwitch, setOpen, setId });

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {t`Список подключений`}
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <MDButton variant="gradient" color="info" href="/strategy" >{t`добавить`} </MDButton>

        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder={true}
          entriesPerPage={false}
         
        />
      </MDBox>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle sx={{ color: "#344767" }}>{t`Внимание`}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#344767" }}>
            {t`Вы уверенны что хотите удалить подключение?`}
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t`Отмена`}</Button>
          <Button onClick={handleOK}>{t`Я уверен(а)`}</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );


}

export default Projects;
