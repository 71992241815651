/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import axios from 'axios';
import { useState } from "react";


// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import ChPassCard from "../../examples/Cards/InfoCards/ChPassCard";

// Overview page components
import Header from "../../layouts/profile/components/Header";
import Load from "../../examples/Load";
import { url_serversq } from "../../context/var";
import { t } from "ttag";

function Overview() {
  const [profile, setProfile] = useState(() => { return false });

  if (!profile) {

    let s_key = localStorage.getItem('s_key');
    let locale = localStorage.getItem("lang") || 'ru';

    axios.post(url_serversq + "/getprofile.php", {
      s_key: s_key, locale: locale,
    }).then(res => {

      if (res.data !== false) {
        //console.log(res.data);
        setProfile(res.data);
      } else {
        //alert(t`Ошибка связи с сервером`);
        window.location.href = "/"
      }
    }).catch(() => {
      //alert(t`Ошибка связи с сервером`);
      window.location.href = "/"

    })
  }

  if (profile)
    return (

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Header profiles={profile}>
          <MDBox mt={5} mb={3} >
            <Grid container spacing={1} justifyContent="center">

              <Grid item xs={12} sm={6} md={6} lg={6} xl={5}>

                <ProfileInfoCard
                  title={t`Основная информация`}
                  info={profile}
                  shadow={true}
                />     </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={5}>

                <ChPassCard
                  title={t`Смена пароля`}
                  info={profile}
                  shadow={true}
                />

              </Grid>

            </Grid>
          </MDBox>


        </Header>
        <Footer />
      </DashboardLayout>
    );
  else return (<div style={{
    width: '100vw',
    height: '100vh',
  }}><Load /></div>);;
}

export default Overview;
