/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";

import Finandy from "./Finandy";
import Binance from "./Binance";
import RoboFX from "./RoboFX";
import { Button, Card, Link, Stack } from "@mui/material";
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import { useLocation } from "react-router";
import MDTypography from "../../components/MDTypography";
import { t } from "ttag";

function Overview() {

    const route = useLocation().pathname.split("/").slice(1);


    return (

        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />

            <MDBox position="relative" mb={5}>
                <MDBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="10.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info1.main, 0.6),
                                rgba(gradients.info1.state, 0.6)
                            )}, url(${backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }} />
                <Card
                    sx={{
                        position: "relative",
                        mt: -10,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}>
                    <MDBox mt={5} mb={3} >
                        <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                            {t`Инструкции`}
                        </MDTypography>
                        <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                            🔸
                            <a className="aVid" target="_blank" rel="noreferrer" href={'https://telegra.ph/Instrukcii-SoNat-Forex-02-20'}>
                                {t`Регистрация`}
                            </a>
                        </MDTypography>
                     </MDBox>
                </Card>
            </MDBox >
            <Footer />
        </DashboardLayout >
    );

}

export default Overview;
