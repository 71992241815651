/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { t, jt } from 'ttag';

// Material Dashboard 2 React base styles

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Switch, TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

export default function data({ das, handleSwitch, setOpen, setId }) {


  const Company = ({ name, id_cabinet, copy_key }) => (
    <a target="_blank" href={copy_key}>
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        {/* <MDAvatar src={image} name={name} size="sm" /> */}
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1} >
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="medium" ml={1}>
          ({id_cabinet})
        </MDTypography>
      </MDBox>
    </a>
  );

  var rw = [];

  das.forEach(element => {

    rw.push({
      name: <Company name={element.name} id_cabinet={element.id_cabinet} copy_key={element.copy_key} />,
      dtstart: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {element.dt_start.slice(0, 10)}
        </MDTypography>
      ),
      profit: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          ${element.profit}
        </MDTypography>
      ),
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {element.status}
        </MDTypography>
      ),
      renew: (element.gr == 1 ?
        <MDBox width="8em" textAlign="center">
          <div>
            <Switch
              value={element.id}
              checked={element.ap}
              onChange={handleSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>

        </MDBox> : null
      ),
      action: <div>
        <MDTypography component={Link} onClick={() => { setId(element.id); setOpen(true); }} variant="body2" color="secondary">
          <Tooltip title={"delete"} placement="top">
            <Icon>delete</Icon>
          </Tooltip>
        </MDTypography>

      </div>
      ,
    });
  });

  {/* <MDButton variant="gradient" id="subBTN" color="info" name={element.id} onClick={btnEdit} fullWidth >настройки</MDButton> */ }
  return {
    columns: [
      { Header: t`Название`, accessor: "name", width: "45%", align: "left" },
      { Header: t`Дата подключения`, accessor: "dtstart", align: "center" },
      { Header: t`Profit`, accessor: "profit", width: "10%", align: "left" },
      { Header: t`Статус`, accessor: "status", align: "center" },
      { Header: t`Автопродление`, accessor: "renew", align: "center" },
      { Header: t`Действие`, accessor: "action", align: "center" },
    ],
    rows: rw

  };
}
