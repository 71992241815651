/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";

// Images
import usdtLogo from "../../../../assets/images/logos/usdtlogo.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../../context";
import { Box, Button, MenuItem, MobileStepper, Select, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import axios from 'axios';
import { url_serversq } from "../../../../context/var";
import Load from "../../../../examples/Load";
import { display, height } from "@mui/system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { t, jt } from 'ttag';

function Withdrowal({ das, setDas, setOper }) {
  const [isload, setLoad] = useState(() => { return false });
  const [isload1, setLoad1] = useState(() => { return false });
  const [controller] = useMaterialUIController();
  const [activeStep, setActiveStep] = useState(() => { return 0 });

  const [req, setReq] = useState(() => {
    return {
      ptype: 1,
      wallet: "",
      amount: 10,
      eKey: "",
      eKeye: false,
    }
  })
  const changeInputeKey = event => {
    //console.log(1);
    event.persist()
    setReq(prev => { return { ...prev, eKey: event.target.value, } });

  }
  const handleChange = (event) => {
    //console.log(2);
    var vol = null;
    if (event.target.type == "number")
      vol = event.target.value;
    else
      vol = event.target.value;
    setReq(prev => {
      return { ...prev, [event.target.name]: vol, }
    });
  };

  const handleSave = (event) => {
    //console.log(3);
    event.preventDefault();

    if (req.eKey == false && req.ptype == 1) {
      setLoad1(true);
      let s_key = localStorage.getItem("s_key");
   
      axios.post(url_serversq + "/sendkey1.php", {
        email: das.email, s_key:s_key,
      }).then(res => {
        if (res.data !== false) {

          setReq(prev => { return { ...prev, eKey: "", eKeye: res.data, } })
          setLoad1(false);
        } else {
          alert("There is already a user with this email");
          setLoad1(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad1(false);
      })
    } else {
      if (req.wallet == "" || req.amount == 0) {
        alert(t`Пожалуйста заполните все данные`);
      } else if ( Math.round(parseFloat(req.amount)*100) >  Math.round(parseFloat(das.bonus)*100) && req.ptype == 1) {
        alert(t`Сумма превышает допустимую`);
        setDas(false);
      } else {
        let s_key = localStorage.getItem("s_key");
        let reqa = { ...req, ekod: req.eKey, s_key: s_key }
        setLoad(true);
        axios.post(url_serversq + "/addreq.php", reqa).then(res => {

          console.log(res.data);

          if (res.data !== false) {
            alert(res.data);
            setLoad1(false);
            setDas(false);
            setOper(0)
          } else {
            alert(t`Ошибка добавления, неправильно введенные данные`);
            setLoad1(false);
            setDas(false);
          }
        }).catch(() => {
          alert("An error occurred on the server");
          setLoad1(false);
          setDas(false);
        })
      }


    }
  };

  if (isload)
    return (<Load />);

  const steps = [
    {
      label: t`Параметры`,
      description: (
        <MDBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>

              <MDBox
                borderRadius="lg"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox >
                  <MDTypography variant="button" fontWeight="bold" mr={2}>
                    {t`Сумма вывода`}: &nbsp;&nbsp;
                  </MDTypography>
                  <MDInput type="number" variant="standard" disabled={req.eKeye ? true : false} fontWeight="bold" name="amount" value={req.amount} onChange={handleChange} />
                </MDBox>
                <MDBox display="flex">
                  <MDTypography variant="button" fontWeight="bold" mr={2}>
                    {t`Счет USDT TRC-20 на который выводите`}: &nbsp;&nbsp;
                  </MDTypography>


                  <MDInput type="text" variant="standard" disabled={req.eKeye ? true : false} fullWidth name="wallet" value={req.wallet} onChange={handleChange} />
                </MDBox>
                <MDBox display="flex" flexDirection="column" mt={3}>
                  <MDTypography variant="caption" color="text" fontWeight="regular">
                    {t`Комиссия 4 USDT`}
                  </MDTypography>
                  <MDTypography variant="caption" color="text" fontWeight="regular">
                    {t`Минимальная сумма вывода 10 USDT`}
                  </MDTypography>
                  <MDTypography variant="caption" color="text" fontWeight="regular">
                    {t`Заявки обрабатываются в течении 5 рабочих дней`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ),
    },
    {
      label: t`Подтверждение`,
      description: (
        <MDBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MDBox key={"key"} >
                <MDBox p={4} key={"key1"} display="flex" justifyContent="center">
                  <MDTypography variant="button" fontWeight="bold" >
                    Email key: &nbsp;&nbsp;
                  </MDTypography>
                  <MDInput type="text" variant="standard" value={req.eKey} onChange={changeInputeKey} /> &nbsp;&nbsp;
                </MDBox>



                <MDBox p={4} key={"key3"} display="flex" justifyContent="center">
                  {isload1 ? <Load /> :
                    <MDButton variant="gradient" color="success" onClick={handleSave}>
                      {!req.eKeye ? t`Получить ключ` : t`Подтвердить`}
                    </MDButton>}
                </MDBox>

              </MDBox>
            </Grid>
          </Grid>
        </MDBox >
      ),
    },
  ];

  const handleNext = () => {
    if (activeStep === 1) {
      if ((parseFloat(req.amount)) > parseFloat(das.bonus)) {
        alert(t`Сумма превышает допустимую`);
      } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === steps.length - 1) {
      setReq(prev => {
        return { ...prev, eKeye: false, }
      });
      setActiveStep(0);
    } else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOper(0)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (<Card style={{ justifyContent: 'space-between' }}>
    <div style={{ minHeight: 350 }}>
      <MDBox pt={2} px={2} >
        <MDTypography variant="h6" fontWeight="medium" align="center">
          {t`Создание заявки на вывод`}
        </MDTypography>
      </MDBox>
      <MDBox style={{
        minHeight: '90%', display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}>
        {steps[activeStep].description}
      </MDBox>
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{ flexGrow: 1 }}
        nextButton={
          <MDButton variant="gradient" color="info" size="small" onClick={handleNext}>
            {activeStep === steps.length - 1 ? t`Заново` : t`Далее`}
            <KeyboardArrowRight />
          </MDButton>
        }
        backButton={
          <MDButton variant="gradient" color="info" size="small" onClick={handleBack}>
            <KeyboardArrowLeft />
            {t`Назад`}
          </MDButton>
        }
      />
    </div>
  </Card>
  );


}

export default Withdrowal;
