/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card } from '@mui/material';
import { t, jt } from 'ttag';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function ChartProf({ das }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: t`Статистика`,
      },
    },
  };

  let labels = [];
  let bal = [];
  das.forEach(el => {
    labels.push(new Date(el.dt * 86400000).toLocaleDateString());
    bal.push(el.profit);
  });

  //  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];//das.labels;

  /*   let data = {
      labels,
      datasets: [
        {
          label: 'Инвестиция',
          data: das.datai,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Баланс',
          data: das.datab,
          borderColor: 'rgb(255, 0, 0)',
          backgroundColor: 'rgba(255, 0, 0, 0.5)',
        },
      ],
    }; */

  const data = {
    labels,
    datasets: [
   
      {
        label: 'Profit',
        data: bal,
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
      },
    ],
  };
  return <Card mt={2}>
    <Line options={options} data={data} /></Card>
}

export default ChartProf;
