/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import Iframe from "react-iframe";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { url_serversq } from "../../context/var";

// Images

import { Divider, Grid } from "@mui/material";
import { t } from "ttag";

function RoboFX() {
    let locale = localStorage.getItem("lang") || 'ru';

    let lo = locale == "ru" ? "" : "_en";


    return (

        <MDBox mt={5} mb={3} >
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`После регистрации в разделе "Структура" вам станут доступны 2 ссылки:`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                🔸 {t`Реферальная ссылка. Ею вы можете делиться со своими партнерами и приглашать в компанию. `}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                🔸 {t`Ссылка для регистрации на РобоФорексе. `}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/n1" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="h4" fontWeight="medium" mt={3} mb={3} align={"center"} style={{ color: 'rgb(216,0,0)' }}>
                {t`Регистрация у брокера и открытие торгового счета.`}
            </MDTypography>

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`После перехода по ссылке "Регистрация на RoboForex", вы попадаете на страницу регистрации брокера РобоФорекс:`}
            </MDTypography>

            <MDBox component="img" src={url_serversq + "/img/rf/1" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <Divider />

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Справа на странице кликаем кнопку "Открыть торговый счет". После чего вводим все поля с регистрационными данными.`}
            </MDTypography>
            <MDTypography variant="subtitle2" paragraph={true} fontWeight="regular">
                {t`КОД ПАРТНЕРА НИГДЕ ВВОДИТЬ НЕ НУЖНО! ОН УЖЕ ВШИТ В ПАРТНЕРСКУЮ ССЫЛКУ. `}   </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/2" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <Divider />

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Кликаем "Следующий шаг" и приступаем к открытию реального торгового счета:`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/3" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Для каждой стратегии необходим отдельный торговый счет определенного типа:`}
            </MDTypography>
            <Grid container spacing={3} pb={3}>
                <Grid item xs={6}>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 1: SN MIXT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 ECN</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:500</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 2: SN GOLD</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:300</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 200$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 3: SN PRO</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:300</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 4: SN 100</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:1000</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 100$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 5: SN CENT X</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:300</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 200$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 6: SN TOP</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:300</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 7: SN CRAZY</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO-CENT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:500</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 8: SN BOOST</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:300</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>
                </Grid>
                <Grid item xs={6}>


                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 9: SN KING</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 ECN</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:500</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 10: SN BLACK</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 ECN</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:500</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 300$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 11: SN TIME</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 ECN</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:500</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 200$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 12: SN AIR</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO-CENT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:1000</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 100$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 13: SN LIFE</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO-CENT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:1000</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 100$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 14: SN STAR</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO-CENT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:1000</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 100$</MDTypography>

                    <MDTypography variant="body1" ml={4} mt={3} fontWeight="bold">🔸 {t`Стратегия`} 15: SN CITY</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Тип счета:`} MT4 PRO-CENT</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Кредитное плечо:`} 1:1000</MDTypography>
                    <MDTypography variant="body1" ml={4} fontWeight="regular">{t`Минимальный депозит:`} 100$</MDTypography>
                </Grid>
            </Grid>


            <Divider />

            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`После создания счета, необходимо подтвердить свою личность путем прохождения верификации аккаунта.`}
            </MDTypography>

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Для этого кликаем соответствующую кнопку вверху страницы вашего кабинета. Следуйте автоматической инструкции для прохождения этого пункта.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`После прохождения верификации, вы получите e-mail с подтверждением. В самом кабинете также отобразится статус успешности прохождения верификации.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`Пополнение депозита рекомендуем осуществлять ПОСЛЕ прохождения верификации.`}
            </MDTypography>
            <Divider />



            <MDTypography variant="h4" fontWeight="medium" mt={3} mb={3} align={"center"} style={{ color: 'rgb(216,0,0)' }}>
                {t`ПОДКЛЮЧЕНИЕ К КОПИРОВАНИЮ.`} </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`После регистрации на платформе РобоФорекс и прохождения верификации, необходимо подключиться к Мастер счету для копирования сделок.`}
            </MDTypography>


            <MDBox component="img" src={url_serversq + "/img/rf/n2" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`Для этого в кабинете Сонат в разделе "Стратегии" необходимо выбрать стратегию и нажать кнопку Подключить.`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/n3" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`Вы увидите ссылку для подключения к копированию выбранной стратегии.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold" style={{ color: 'rgb(216,0,0)' }}>
                ❗️❗️❗️{t`Подключение следует выполнить между проторговками. Либо, когда счет разгружен и нет сильного перекоса в сторону просадки или профита.  `}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold" style={{ color: 'rgb(216,0,0)' }}>
                {t`Главное правило - нет перегруза счета в сторону просадки или профита.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold" style={{ color: 'rgb(216,0,0)' }}>
                {t`Эти данные можно получить онлайн в FXMonitor SoNat.`}
            </MDTypography>


            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`После перехода по ссылке вы попадете на сайт RoboForex в раздел CopyFX в кабинет выбранной стратегии.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Справа на странице необходимо выбрать счет, которым вы хотите подключиться к копированию.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`Если тип счета или баланс не соответствуют условиям, вы увидите данное сообщение:`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/5" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDBox component="img" src={url_serversq + "/img/rf/51" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Если все подготовленно верно, будет доступна кнопка "Подключиться к трейдеру". Кликаем ее и подключаемся.`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                {t`После этого необходимо вернуться в кабинет Сонат и вписать номер счета в соответствующее поле. Нажать Сохранить!`}
            </MDTypography>

            <MDBox component="img" src={url_serversq + "/img/rf/n3" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`!!! Каждая стратегия подключается отдельно на отдельный, заранее подготовленный по типу и балансу, счет.`}
            </MDTypography>

            <MDTypography variant="body1" paragraph={true} fontWeight="bold">
                ❗️{t`Проделываем эту операцию с остальными стратегиями`}
            </MDTypography>


            <MDTypography variant="h4" fontWeight="medium" mt={3} mb={3} align={"center"} style={{ color: 'rgb(216,0,0)' }}>
                {t`ДЛЯ ЛИДЕРОВ.`}
            </MDTypography>

            <MDTypography variant="body1" mb={2} paragraph={true} fontWeight="bold">
                {t`Каждому лидеру необходимо создать 2 собственные партнерские ссылки, которые он сможет дать своим партнерам.`}
            </MDTypography>

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                🔸{t`Партнерская ссылка для регистрации на РобоФорексе`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                🔸{t`Партнерская ссылка для подключения к копированию`}
            </MDTypography>


            <MDTypography variant="body1" mt={4} mb={2} paragraph={true} fontWeight="bold">
                {t`Ваша реферальная ссылка для регистрации на платформе SoNat находится в разделе "Структура" в личном кабинете.`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/pp3" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" mt={4} mb={2} paragraph={true} fontWeight="bold">
                {t` Как создать ссылку для регистрации на платформе брокера РобоФорекс?`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Для этого в кабинете переходим во вкладку "Партнеру" -  "Партнерские ссылки":`}
            </MDTypography>

            <MDBox component="img" src={url_serversq + "/img/rf/7" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`Здесь будет предложено создать партнерский счет RoboForex. Данный счет необходим для начисления партнерских вознаграждений от самого брокера (комиссии со спредов).`}
            </MDTypography>
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`После создания счета, вам будет доступна ваша партнерская ссылка:`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/8" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" mt={4} mb={2} paragraph={true} fontWeight="bold">
                {t`Ссылка постоянная, всегда актуальная и принадлежит только вам. Все партнеры, зарегистрированные по ней, будут находиться в вашей структуре в 10 уровней глубины. `}
            </MDTypography>
            <MDTypography variant="body1" mt={4} mb={2} paragraph={true} fontWeight="regular">
                {t`Полученную ссылку необходимо внести в кабинет Сонат`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/n4" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" mb={2} paragraph={true} fontWeight="bold">
                {t`После этого все партнеры, зарегистрированные по вашей ссылке СОНАТ увидят именно вашу ссылку для регистрации на РобоФорекс. `}
            </MDTypography>
            <MDTypography variant="body1" mb={2} paragraph={true} fontWeight="bold">
                {t`Ссылки для подключения к копированию генерируются уже автоматически. `}
            </MDTypography>
            <MDTypography variant="h4" fontWeight="medium" mt={3} mb={3} align={"center"} style={{ color: 'rgb(216,0,0)' }}>
                {t`Партнерская программа SoNat от копировании.`}
            </MDTypography>




            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`1. Партнерская программа RoboForex Affiliate.`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/pp1" + lo + ".png"} mt={2} maxWidth={'100%'} />
            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`2. Партнерская программа SoNat CopyFX`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/pp2" + lo + ".png"} mt={2} maxWidth={'100%'} />

            <MDTypography variant="body1" paragraph={true} fontWeight="regular">
                {t`ПРИМЕР РАСЧЕТА ПО ПАРТНЕРСКИМ ПРОГРАММАМ СОНАТ`}
            </MDTypography>
            <MDBox component="img" src={url_serversq + "/img/rf/pp3" + lo + ".jpg"} mt={2} maxWidth={'100%'} />



        </MDBox>

    );

}

export default RoboFX;
