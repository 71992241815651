/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import { t } from "ttag";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 React base styles

export default function data({ das, btnDel }) {


 
    const Company = ({ name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            {/* <MDAvatar src={image} name={name} size="sm" /> */}
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1} >
                {name}
            </MDTypography>
        </MDBox>
    );


    var rw = [];

    das.trans.forEach(element => {
        rw.push({
            id: <Company name={element.id} />,
            dt: <Company name={element.dt} />,
            amount: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    ${element.amount}
                </MDTypography>
            ),
            komment: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    {element.komment}
                </MDTypography>
            ),
           
        });
    });


    return {
        columns: [
            { Header: t`Сумма`, accessor: "amount", width: "10%", align: "center" },
            { Header: t`Комментарий`, accessor: "komment", width: "50%", align: "left" },
            { Header: t`Дата`, accessor: "dt", width: "15%", align: "left" },
            { Header: t`Номер`, accessor: "id", width: "5%", align: "center" },
        ],
        rows: rw

    };
}
