/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDSnackbar from "../../components/MDSnackbar";
import axios from 'axios';
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Load from "../../examples/Load";

// Data 
import { url_serversq, number_format } from "../../context/var";

// Dashboard components
import StructureOverview from "./components/StructureOverview";
import ChartProf from "./components/ChartProf";

import { t, jt } from 'ttag';
import Operation from "./components/Operation";
import PoolInfo from "./components/PoolInfo";
import AddDep from "./components/AddDep";
import OutDep from "./components/OutDep";
import MoveDep from "./components/MoveDep";
import Requests from "../billing/components/Requests";

function Pools() {

  const [das, setDas] = useState(() => {
    return false
  });
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [infoSB, setInfoSB] = useState(false);

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t`Информация`}
      content={t`Реферальная ссылка скопирована в буфер обмена`}
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  function sortByInd(arr) {
    arr.sort((a, b) => parseInt(a.ind) > parseInt(b.ind) ? 1 : -1);
  }

  if (!das) {
    let locale = localStorage.getItem("lang") || 'ru';
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getpool.php", {
      s_key: s_key, locale: locale,
    }).then(res => {
      // localStorage.clear();
      //console.log(res.data);

      if (res.data != false) {
        var dd = res.data
        //sortByInd(dd.dtl)
        setDas(dd);
      } else {
        //alert(t`Ошибка связи с сервером`);
        localStorage.clear();
        window.location.href = "/"
      }
    })
  }

  if (das) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="info"
                        icon="money"
                        title="USDT"
                        count={number_format(parseFloat(das.bonus).toFixed(2), 2)}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Доступный баланс`,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="success"
                        icon="star"
                        title="USDT"
                        count={number_format(das.balanceinpoolX1 + das.balanceinpoolX2 + das.balanceinpoolX3 + das.balanceinpoolMixt, 2)}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Баланс в пуле`,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="primary"
                        icon="peoples"
                        title={t`USDT`}
                        count={number_format(das.poolusdt, 2)}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Общий объем пула`,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="primary"
                        icon="peoples"
                        title={t`USDT`}
                        count={number_format(das.poolusdtM, 2)}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Объем пула ` + das.poolusdtMN,
                        }}
                      />
                    </MDBox>
                  </Grid>

                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    {das.oper == 0 ? <Operation das={das} actionUPD={setDas} /> : null}
                    {das.oper == 1 ? <AddDep das={das} setDas={setDas} /> : null}
                    {das.oper == 2 ? <OutDep das={das} setDas={setDas} /> : null}
                    {das.oper == 3 ? <MoveDep das={das} setDas={setDas} /> : null}
                  </Grid>
                  <Grid item xs={12} >
                    <Requests das={das} setDas={setDas} />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </MDBox>
        </MDBox>
        {renderInfoSB}
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Pools;


