/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { t } from "ttag";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import DataTable from "../../../../examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "../../../../layouts/billing/components/Transaction";
import data from "./data";

function Transactions({ das }) {
  const { columns, rows } = data({ das });
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {t`Транзакции`}
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          {/*<MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>
           <MDTypography variant="button" color="text" fontWeight="regular">
            23 - 30 March 2020
          </MDTypography> */}
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={true}
          noEndBorder
          entriesPerPage={false}
        />


      </MDBox>
    </Card>
  );
}

export default Transactions;
