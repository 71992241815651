import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Breadcrumbs, Chip, FormControlLabel, Switch } from "@mui/material";
import MDBox from "../../components/MDBox";
import { t } from "ttag";

function genData(spr) {
    let res = [];
    for (let index = 0; index <= 11; index++) {
        if (spr['l' + index]) {
            let ch = [];
            let array = spr['l' + index];
            let s = {}
            for (let i9 = 44; i9 <= 50; i9++) {
                if (!(i9 == 45 || i9 == 46 || i9 == 47 || i9 == 48))
                    s['s' + i9] = 0;
            }
            for (let i1 = 0; i1 < array.length; i1++) {
                let el = array[i1];
                let tt = 0
                let ttt = {}
                for (let i9 = 44; i9 <= 50; i9++) {
                    if (!(i9 == 45 || i9 == 46 || i9 == 47 || i9 == 48)) {
                        tt += parseFloat(el['s' + i9] || 0)
                        ttt['s' + i9] = number_format(el['s' + i9]);
                        s['s' + i9] = parseFloat(s['s' + i9]) + parseFloat(el['s' + i9] || 0)
                    }
                }

                ch.push({
                    name: el.name,
                    email: el.name,
                    type: "string",
                    parentId: 'Level-' + index,
                    total: number_format(tt),
                    ...ttt,
                },);


            }
            let tt = 0
            for (let i9 = 44; i9 <= 50; i9++) {
                if (!(i9 == 45 || i9 == 46 || i9 == 47 || i9 == 48)) {
                    tt += parseFloat(s['s' + i9] || 0)
                    s['s' + i9] = number_format(s['s' + i9])
                }
            }


            res.push({
                name: 'Level-' + index,
                email: 'Level ' + index,
                type: "container",
                tableData: { isTreeExpanded: false },
                parentId: undefined,
                total: number_format(tt),
                ...s,
            },);
            res.push(...ch)
        }
    }
    if (spr['itg']) {
        let el = spr['itg'];
        let tt = 0
        let ttt = {}
        for (let i9 = 44; i9 <= 50; i9++) {
            if (!(i9 == 45 || i9 == 46 || i9 == 47 || i9 == 48)) {
                tt += parseFloat(el['s' + i9] || 0)
                ttt['s' + i9] = number_format(el['s' + i9]);
            }
        }
        res.push({
            name: 'total',
            email: 'Total',
            type: "string",
            total: number_format(tt),
            parentId: undefined,
            ...ttt,
        },);

    }
    return res
}

export default function RefData({ orgChart }) {
    const [defaultFilter, setDefaultFilter] = useState(undefined);
    //const [path, setPath] = useState(["container-3", "text-3"]);
    const [path, setPath] = useState(["empty"]);
    const [showAllFields, setShowAllFields] = useState(true);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const tableRef = useRef();
    // table data defines some parent / child relationships
    const [tableData, settableData] = useState(false);
    if (!tableData)
        settableData(genData(orgChart.ref))
    //const tableData = tableData ? genData(orgChart.ref) : tableData;

    const calculatePath = selection => {
        let field = selection;
        const segments = [];
        while (field) {
            segments.unshift(field.name);
            if (field.parentId) {
                const parentId = field.parentId;
                field = tableData.find(tableItem => {
                    return tableItem.name === parentId ? tableItem : undefined;
                });
            } else {
                field = undefined;
            }
        }

        return segments;
    };

    const handleToggleFieldsChange = (_event, checked) => {
        setShowAllFields(checked);
        if (checked) {
            setDefaultFilter(undefined);
        } else {
            setDefaultFilter("string");
        }
    };

    const renderPathSegments = () => {
        return (
            <Breadcrumbs aria-label="breadcrumb">
                {path.map((segment, index) => {
                    return (
                        <Chip
                            key={index}
                            label={segment}
                            color={
                                ["empty", "form"].includes(segment) ? "secondary" : "primary"
                            }
                            variant="outlined"
                            size="small"
                        />
                    );
                })}
            </Breadcrumbs>
        );
    };

    /**
     * this use effect restores the selection state
     */
    useEffect(() => {
        if (tableRef && tableRef.current) {
            const current = tableRef.current;
            const dataManager = current.dataManager;
            if (dataManager.data.length > 0) {
                const selectedPath = [...path].filter(segment => segment !== "empty");
                let selectedRow;

                dataManager.data.forEach(item => {
                    if (item.type === "container") {
                        item.tableData.isTreeExpanded = false;
                    }
                });

                while (selectedPath.length > 0) {
                    const row = dataManager.data.find(
                        rowData => rowData.name === selectedPath[0]
                    );
                    if (row && row.type === "container") {
                        row.tableData.isTreeExpanded = true;
                    } else {
                        selectedRow = row;
                    }

                    selectedPath.shift();
                }

                if (selectedRow && selectedRowId !== selectedRow.tableData.id) {
                    current.onSelectionChange(selectedRow);
                }
            }
        }
    }, [defaultFilter, path, selectedRowId, tableRef]);

    return (
        <MDBox style={{ maxWidth: "100%", overflow: "auto", borderRadius: "30px 30px 0 0" }}>
            <div className="App" >
                <MaterialTable
                    //tableRef={tableRef}
                    title="Copy FX"
                    data={tableData}
                    columns={[
                        { title: t`Партнёр`, field: "email" },
                        { title: t`Всего`, field: "total" },
                        { title: "SoNat X", field: "s44" },
/*                         { title: "SoNat X2", field: "s45" },
                        { title: "SoNat X3", field: "s46" },
                        { title: "SoNat MIXT", field: "s47" },
                        { title: "SoNat ULTRA", field: "s48" },
 */                        { title: "SoNat Black", field: "s49" },
                        { title: "SoNat White", field: "s50" },
                    ]}
                    onRowClick={(evt, selectedRow) => {
                        setSelectedRowId(selectedRow.tableData.id);
                        setPath(calculatePath(selectedRow));
                    }}
                    onSelectionChange={(data, rowData) => {
                        if (rowData) {
                            setSelectedRowId(rowData.tableData.id);
                            setPath(calculatePath(rowData));
                        }
                    }}
                    options={{
                        defaultExpanded: false,
                        maxBodyHeight: "800px",
                        paging: false,
                        searchFieldAlignment: "left",
                        rowStyle: rowData => ({
                            fontSize: "16px",
                            paddingTop: "0px",
                            backgroundColor:
                                selectedRowId === rowData.tableData.id ? "#EEE" : "#FFF"
                        })
                    }}
                    parentChildData={(row, rows) => rows.find(a => a.name === row.parentId)}
                    components={{
                        // eslint-disable-next-line react/display-name
                        Toolbar: tbProps => {
                            return (
                                <div style={{ backgroundColor: '#ffbf54e0', borderRadius: "30px 30px 0 0" }}>
                                    <MTableToolbar  {...tbProps} />
                                </div>
                            );
                        }
                    }}
                />
            </div>
        </MDBox>
    );
}

function number_format(number, decimals, dec_point, thousands_sep) {  // Format a number with grouped thousands
    var i, j, kw, kd, km;
    if (!number && number != "0") return "-"
    if (isNaN(decimals = Math.abs(decimals))) {
        decimals = 2;
    }
    if (dec_point == undefined) {
        dec_point = ",";
    }
    if (thousands_sep == undefined) {
        thousands_sep = ".";
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

    if ((j = i.length) > 3) {
        j = j % 3;
    } else {
        j = 0;
    }

    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
}
