import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Breadcrumbs, Chip, FormControlLabel, Switch } from "@mui/material";
import MDBox from "../../components/MDBox";
import { t } from "ttag";
import { borderBottom, borderRadius } from "@mui/system";

function genData(spr) {
    //console.log(spr);
    let res = [];
    var ip1 = 0
    var ip2 = 0
    var ip3 = 0
    var ipMixt = 0

    for (let index = 0; index <= 11; index++) {
        if (spr['l' + index]) {
            let ch = [];
            let array = spr['l' + index];
            let s23 = 0
            let s24 = 0
            let s28 = 0
            let s29 = 0
            let p1 = 0
            let p2 = 0
            let p3 = 0
            let pMixt = 0
            for (let i1 = 0; i1 < array.length; i1++) {
                let el = array[i1];
                let tt = 0
                tt += parseFloat(el.s23 || 0)
                tt += parseFloat(el.s24 || 0)
                tt += parseFloat(el.s28 || 0)
                tt += parseFloat(el.s29 || 0)
                ch.push({
                    name: el.name,
                    email: el.name,
                    type: "string",
                    parentId: 'Level-' + index,
                    total: number_format(tt),
                    s23: number_format(el.s23),
                    s24: number_format(el.s24),
                    s28: number_format(el.s28),
                    s29: number_format(el.s29),
                    p1: number_format(el.p1),
                    p2: number_format(el.p2),
                    p3: number_format(el.p3),
                    pMixt: number_format(el.pMixt),
                },);

                s23 += parseFloat(el.s23 || 0)
                s24 += parseFloat(el.s24 || 0)
                s28 += parseFloat(el.s28 || 0)
                s29 += parseFloat(el.s29 || 0)
                p1 += parseFloat(el.p1 || 0)
                p2 += parseFloat(el.p2 || 0)
                p3 += parseFloat(el.p3 || 0)
                pMixt += parseFloat(el.pMixt || 0)
                ip1 += parseFloat(el.p1 || 0)
                ip2 += parseFloat(el.p2 || 0)
                ip3 += parseFloat(el.p3 || 0)
                ipMixt += parseFloat(el.pMixt || 0)
            }
            let tt = 0
            tt += parseFloat(s23 || 0)
            tt += parseFloat(s24 || 0)
            tt += parseFloat(s28 || 0)
            tt += parseFloat(s29 || 0)

            res.push({
                name: 'Level-' + index,
                email: 'Level ' + index,
                type: "container",
                tableData: { isTreeExpanded: false },
                parentId: undefined,
                total: number_format(tt),
                s23: number_format(s23),
                s24: number_format(s24),
                s28: number_format(s28),
                s29: number_format(s29),
                p1: number_format(p1),
                p2: number_format(p2),
                p3: number_format(p3),
                pMixt: number_format(pMixt),
            },);
            res.push(...ch)
        }
    }
    if (spr['itg']) {
        let el = spr['itg'];
        let tt = 0
        tt += parseFloat(el.s23 || 0)
        tt += parseFloat(el.s24 || 0)
        tt += parseFloat(el.s28 || 0)
        tt += parseFloat(el.s29 || 0)
        res.push({
            name: 'total',
            email: 'Total',
            type: "string",
            total: number_format(tt),
            parentId: undefined,
            s23: number_format(el.s23),
            s24: number_format(el.s24),
            s28: number_format(el.s28),
            s29: number_format(el.s29),
            p1: number_format(ip1),
            p2: number_format(ip2),
            p3: number_format(ip3),
            pMixt: number_format(ipMixt),
        },);

    }
    return res
}

export default function PoolRefData({ orgChart }) {
    const [defaultFilter, setDefaultFilter] = useState(undefined);
    //const [path, setPath] = useState(["container-3", "text-3"]);
    const [path, setPath] = useState(["empty"]);
    const [showAllFields, setShowAllFields] = useState(true);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const tableRef = useRef();
    // table data defines some parent / child relationships
    const [tableData, settableData] = useState(false);
    if (!tableData)
        settableData(genData(orgChart.ref))
    //const tableData = tableData ? genData(orgChart.ref) : tableData;

    const calculatePath = selection => {
        let field = selection;
        const segments = [];
        while (field) {
            segments.unshift(field.name);
            if (field.parentId) {
                const parentId = field.parentId;
                field = tableData.find(tableItem => {
                    return tableItem.name === parentId ? tableItem : undefined;
                });
            } else {
                field = undefined;
            }
        }

        return segments;
    };

    const handleToggleFieldsChange = (_event, checked) => {
        setShowAllFields(checked);
        if (checked) {
            setDefaultFilter(undefined);
        } else {
            setDefaultFilter("string");
        }
    };

    const renderPathSegments = () => {
        return (
            <Breadcrumbs aria-label="breadcrumb">
                {path.map((segment, index) => {
                    return (
                        <Chip
                            key={index}
                            label={segment}
                            color={
                                ["empty", "form"].includes(segment) ? "secondary" : "primary"
                            }
                            variant="outlined"
                            size="small"
                        />
                    );
                })}
            </Breadcrumbs>
        );
    };

    /**
     * this use effect restores the selection state
     */
    useEffect(() => {
        if (tableRef && tableRef.current) {
            const current = tableRef.current;
            const dataManager = current.dataManager;
            if (dataManager.data.length > 0) {
                const selectedPath = [...path].filter(segment => segment !== "empty");
                let selectedRow;

                dataManager.data.forEach(item => {
                    if (item.type === "container") {
                        item.tableData.isTreeExpanded = false;
                    }
                });

                while (selectedPath.length > 0) {
                    const row = dataManager.data.find(
                        rowData => rowData.name === selectedPath[0]
                    );
                    if (row && row.type === "container") {
                        row.tableData.isTreeExpanded = true;
                    } else {
                        selectedRow = row;
                    }

                    selectedPath.shift();
                }

                if (selectedRow && selectedRowId !== selectedRow.tableData.id) {
                    current.onSelectionChange(selectedRow);
                }
            }
        }
    }, [defaultFilter, path, selectedRowId, tableRef]);

    return (
        <MDBox style={{  overflow: "auto", borderRadius: "30px 30px 0 0" }}>
            <div className="App" >
                <MaterialTable
                    //tableRef={tableRef}
                    title="Pool SN"
                    data={tableData}
                    columns={[
                        { title: t`Партнёр`, field: "email" },
                        { title: t`Всего`, field: "total" },
                        { title: "Pool X1", field: "s24" },
                        { title: "Pool X2", field: "s23" },
                        { title: "Pool X3 ", field: "s28" },
                        { title: "Pool MIXT ", field: "s29" },
                        { title: t`Объём Pool X1`, field: "p2" },
                        { title: t`Объём Pool X2`, field: "p1" },
                        { title: t`Объём Pool X3 `, field: "p3" },
                        { title: t`Объём Pool MIXT `, field: "pMixt" },

                    ]}
                    onRowClick={(evt, selectedRow) => {
                        setSelectedRowId(selectedRow.tableData.id);
                        setPath(calculatePath(selectedRow));
                    }}
                    onSelectionChange={(data, rowData) => {
                        if (rowData) {
                            setSelectedRowId(rowData.tableData.id);
                            setPath(calculatePath(rowData));
                        }
                    }}
                    options={{
                        defaultExpanded: false,
                        maxBodyHeight: "800px",
                        paging: false,
                        searchFieldAlignment: "left",
                        rowStyle: rowData => ({
                            fontSize: "16px",
                            paddingTop: "0px",
                            backgroundColor:
                                selectedRowId === rowData.tableData.id ? "#EEE" : "#FFF"
                        })
                    }}
                    parentChildData={(row, rows) => rows.find(a => a.name === row.parentId)}
                    components={{
                        // eslint-disable-next-line react/display-name
                        Toolbar: tbProps => {
                            return (
                                <div style={{ backgroundColor: '#ffbf54e0', borderRadius: "30px 30px 0 0" }}>
                                    <MTableToolbar  {...tbProps} />
                                </div>
                            );
                        }
                    }}
                />
            </div>
        </MDBox>
    );
}

function number_format(number, decimals, dec_point, thousands_sep) {  // Format a number with grouped thousands
    var i, j, kw, kd, km;
    if (!number && number != "0") return "-"
    if (isNaN(decimals = Math.abs(decimals))) {
        decimals = 2;
    }
    if (dec_point == undefined) {
        dec_point = ",";
    }
    if (thousands_sep == undefined) {
        thousands_sep = ".";
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

    if ((j = i.length) > 3) {
        j = j % 3;
    } else {
        j = 0;
    }

    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
}
