/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
import { useState } from "react";
// prop-types is library for typechecking of props
import { url_serversq } from "../../../../context/var";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import axios from 'axios';
import Load from "../../../../examples/Load";
import VisibilityIcon from '@mui/icons-material/Visibility';
// Material Dashboard 2 React base styles
import MDInput from "../../../../components/MDInput";
import { t } from "ttag";

function ChPassCard({ title, info, shadow }) {
  const [isLoad, setLoad] = useState(() => { return false });

  const [eInfo, seteInfo] = useState(() => { return { OldPass: "", NewPass: "", NewPass1: "", } });
  const [eKey, seteKey] = useState(() => {
    return {
      eKey: false,
      eKeye: false,
    }
  });

  const changeInputRegister = event => {
    event.persist()
    seteInfo(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const changeInputeKey = event => {
    event.persist()
    seteKey(prev => {
      return { ...prev, eKey: event.target.value, }
    });

  }

  const submitChackin = () => {

    if (eKey.eKey == false) {
      setLoad(true);
      axios.post(url_serversq + "/sendkey.php", {
        email: info.email.value,
      }).then(res => {
        if (res.data !== false) {
          seteKey({ eKey: "", eKeye: res.data, });
          setLoad(false);
        } else {
          alert("There is already a user with this email");
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })

    } else if (eInfo.NewPass == eInfo.NewPass1) {
      if (eKey.eKey == eKey.eKeye) {
        seteKey({ eKey: false, eKeye: false, });
        let s_key = localStorage.getItem("s_key");
        let req = { ...eInfo, s_key: s_key }
        setLoad(true);
        axios.post(url_serversq + "/setNewPass.php", req).then(res => {
          if (res.data != false) {
            setLoad(false);
            seteInfo({ OldPass: "", NewPass: "", NewPass1: "", });
            alert(res.data);
          } else {
            setLoad(false);
            seteInfo({ OldPass: "", NewPass: "", NewPass1: "", });
            setLoad(false); alert(t`Непрвильно введен пароль`);
          }
        }).catch(() => {
          setLoad(false);
          seteInfo({ OldPass: "", NewPass: "", NewPass1: "", });
          setLoad(false); alert("An error occurred on the server");
        })
      } else { alert(t`Код подтверждения не совпадает`) };

    } else { alert(t`Пароли не совпадает`) }

  }

  const show_hide_password = (id) => {
    var input = document.getElementById(id);
    if (input)
      if (input.getAttribute('type') == 'password') {
        input.setAttribute('type', 'text');
      } else {
        input.setAttribute('type', 'password');
      }
    return false;
  }

  var renderItems = [];

  renderItems.push(
    <MDBox display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t`Старый пароль:`} &nbsp;&nbsp;
      </MDTypography>
      <MDInput type="password" variant="standard" fullWidth name="OldPass" id="OldPass" value={eInfo.OldPass} onChange={changeInputRegister} />
      <Link onClick={() => show_hide_password("OldPass")} >   <VisibilityIcon /></Link>
    </MDBox>)
  renderItems.push(
    <MDBox display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t`Новый пароль:`} &nbsp;&nbsp;
      </MDTypography>
      <MDInput type="password" variant="standard" fullWidth name="NewPass" id="NewPass" value={eInfo.NewPass} onChange={changeInputRegister} />
      <Link onClick={() => show_hide_password("NewPass")} >   <VisibilityIcon /></Link>
    </MDBox>)
  renderItems.push(
    <MDBox display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t`Повторите пароль:`} &nbsp;&nbsp;
      </MDTypography>
      <MDInput type="password" variant="standard" fullWidth name="NewPass1" id="NewPass1" value={eInfo.NewPass1} onChange={changeInputRegister} />
      <Link onClick={() => show_hide_password("NewPass1")} >   <VisibilityIcon /></Link>
    </MDBox>)


  if (eKey.eKey !== false)
    renderItems.push(
      <MDBox key={"key"} display="flex">
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          Email key: &nbsp;&nbsp;
        </MDTypography>
        <MDInput type="text" variant="standard" fullWidth value={eKey.eKey} onChange={changeInputeKey} /> &nbsp;&nbsp;
      </MDBox>
    );
  if (!isLoad)
    renderItems.push(
      <MDBox key={"key1"} mt={4} mb={1}>
        <MDButton variant="gradient" id="subBTN" color="info" onClick={submitChackin} fullWidth >{t`сохранить`}</MDButton>
      </MDBox>
    ); else
    renderItems.push(
      <MDBox key={"key1"} mt={4} mb={1}>
        <Load />
      </MDBox>
    );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }} >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2} >
        <MDBox >
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ChPassCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard


export default ChPassCard;
