import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Load from '../../examples/Load';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import axios from 'axios';
import { url_serversq, number_format } from "../../context/var";
import DataTable from '../../examples/Tables/DataTable';
import data from "./data";
import MDInput from '../../components/MDInput';
import { timelineClasses } from '@mui/lab';
import { t } from 'ttag';
import { MicSharp } from '@mui/icons-material';

function StatFX() {
    const [das, setDas] = useState(() => { return false });
    const [period, setPeriod] = useState(() => { return -1 });
    const [ind, setInd] = useState(() => { return 0 });
    const [dep, setDep] = useState(() => { return 0 });
    const [trup, setTrup] = useState(() => { return 0 });

    const handleChangeP = (event) => {
        setPeriod(event.target.value);
        calcI(das, event.target.value)
        sortByInd(das, event.target.value)

    };

    const calcI = (tmp, p) => {
        p = p || period
        var sum = 0;
        var ind1 = 0;
        var ind2 = 0;
        let ds = tmp || das
        ds.products.forEach(element => {
            sum += parseFloat(element.vol) || 0;
            ind1 += (parseFloat((element.vol) || 0) / 100) * parseFloat(element.prof[p].prof);
        })
        ind2 = ind1 / (sum / 100)
        setDep(sum);
        setInd(ind2);
    };

    function sortByInd(arr, p) {
        arr.products.sort((a, b) => parseInt(a.prof[p].ind) > parseInt(b.prof[p].ind) ? 1 : -1);
    }

    const readI = (das1) => {

        if (das1) {

            var ds = JSON.parse(localStorage.getItem("svc1")) || null;

            das1.products.forEach(element => {
                element.vol = 300;
                if (ds) ds.forEach(tsv => {
                    if (tsv.id == element.id) {
                        element.vol = parseFloat(tsv.vol) || 0
                    }
                })

            })
        }
        return das1
    };

    const onChangeL = (event) => {
        var ds = das;
        var tosave = []
        ds.products.forEach(element => {
            if (element.id == event.target.name) {
                element.vol = parseFloat(event.target.value) || 0
            }
            tosave.push({ id: element.id, vol: element.vol })
        })
        setDas(prev => {
            return { ...prev, products: ds.products, }
        });

        calcI()
        localStorage.setItem("svc1", JSON.stringify(tosave))
    };
    if (!das) {
        let s_key = localStorage.getItem('s_key');
        axios.post(url_serversq + "/getStatFX.php", {
            s_key: s_key,
            key: 'dl;fkh849yfdg2kgnaqot',
        }).then(res => {
            //console.log(res.data);
            if (res.data != false) {
                setPeriod(res.data.periods)
                let tmp1 = res.data
                let tmp = readI(tmp1)
                sortByInd(tmp, res.data.periods)
                setDas(tmp);
                calcI(tmp, res.data.periods)

            }
        })/* .catch(() => {
            alert(t`Ошибка связи с сервером`);
        }) */
    }


    var sdas = null;
    var spaks = null;

    if (das) {

        var mip = [];
        if (das.period)
            das.period.forEach(element => {
                mip.push(<MenuItem key={"m1" + element.id} value={element.id}>{element.name}</MenuItem>)
            });
        var mis = [];

        var i = 0;
        if (das.products)

            das.products.forEach(element => {
                i += 1
                let clr = '#9191910a';
                if (i <= 5) clr = '#ff9c9c21'
                if (i > 5 && i <= 10) clr = '#fff59c21'
                mis.push(
                    <Grid key={element.id} container sx={{ backgroundColor: clr }}>
                        <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent="center">
                            <MDTypography variant="h6" fontWeight="medium">
                                {element.name}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent="center">
                            <MDInput type="text" value={element.vol} name={element.id} onChange={onChangeL} /> &nbsp;&nbsp;&nbsp;
                        </Grid>
                        <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent="center">
                            <MDInput type="text" disable="true" value={element.prof[period] ? element.prof[period].prof + " %" : 0} /> &nbsp;&nbsp;&nbsp;
                        </Grid>
                    </Grid>)

            });

        spaks =
            <Card>
                <MDTypography p={1} m={2} variant="h4" width="100%" textAlign="center" fontWeight="bold" >
                    {t`Index Калькулятор`}
                </MDTypography>
                <MDBox key={'d2'} p={2}>
                    <Grid container p={2} sx={{ backgroundColor: '#ffa20730', borderRadius: '25px' }}>
                        <Grid item xs={12} md={4} display={'flex'} alignItems={'center'} justifyContent="flex-end">
                            <MDTypography sx={{ width: "50%" }} textAlign="end" variant="h5" fontWeight="medium">
                                {t`Индекс`}&nbsp;&nbsp;&nbsp;
                            </MDTypography>
                            <MDTypography sx={{ width: "50%" }} textAlign="end" variant="h4" fontWeight="blod">
                                {number_format(ind) + " %"}
                            </MDTypography>

                        </Grid>
                        <Grid item pt={1} pb={1} xs={12} md={4} display={'flex'} alignItems={'center'} justifyContent="flex-end">
                            <MDTypography sx={{ width: "50%" }} textAlign="end" variant="h6" fontWeight="medium">
                                {t`Общий депозит`}&nbsp;&nbsp;&nbsp;
                            </MDTypography>
                            <MDInput type="text" sx={{ width: "50%" }} value={dep} disable="true" />
                        </Grid>
                        <Grid item xs={12} md={4} display={'flex'} alignItems={'center'} justifyContent="flex-end">
                            <MDTypography sx={{ width: "50%" }} textAlign="end" variant="h6" fontWeight="medium">
                                {t`Период`}&nbsp;&nbsp;&nbsp;
                            </MDTypography>
                            <FormControl sx={{ width: "50%" }} >
                                <InputLabel id="period-select-label">{t`Период`}</InputLabel>
                                <Select
                                    sx={{ minHeight: '3em' }}
                                    labelId="period-select-label"
                                    value={period}
                                    label={t`Период`}
                                    onChange={handleChangeP}
                                    autoWidth
                                >
                                    {mip}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container mt={1} sx={{ backgroundColor: '#07ff7130', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                        <Grid item xs={4} p={2} display={'flex'} alignItems={'center'} borderBottom={'solid 1px'} justifyContent="center" >
                            <MDTypography variant="h6" fontWeight="medium"> {t`Стратегия`} </MDTypography>
                        </Grid>
                        <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent="center" borderBottom={'solid 1px'} borderRight={'solid 1px'} borderLeft={'solid 1px'}>
                            <MDTypography variant="h6" fontWeight="medium"> {t`Депозит на начало периода`} </MDTypography>
                        </Grid>
                        <Grid item xs={4} display={'flex'} alignItems={'center'} borderBottom={'solid 1px'} justifyContent="center">
                            <MDTypography variant="h6" fontWeight="medium" > {t`Профит за период`} </MDTypography>
                        </Grid>
                    </Grid>
                    {mis}

                </MDBox>
            </Card >
    }
    return (<div>
        <MDBox> {spaks} </MDBox>
        <MDBox p={2}>  {sdas} </MDBox>
    </div>
    );

}


export default StatFX;

