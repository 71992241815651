/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

// Material Dashboard 2 React example components
import TimelineItem from "../../../../examples/Timeline/TimelineItem";


import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import Load from "../../../../examples/Load";
import axios from "axios";
import { url_serversq } from "../../../../context/var";
import { useMaterialUIController } from "../../../../context";
import { t } from "ttag";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIconOff from '@mui/icons-material/VisibilityOff';
import { Button } from "@mui/material";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 10, height: 10 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 10, height: 10 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 10, height: 10 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}
const StyledTreeItemd = styled((props) => (
  <TreeItem {...props} style={{ color: "#FFFF" }} TransitionComponent={TransitionComponent} />
))(({ theme }) => (
  {
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3, color: "#FFFF"
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 0,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`, color: "#FFFF"
    },
  }));

const StyledTreeItemw = styled((props) => (
  <TreeItem {...props} style={{ color: "#000" }} TransitionComponent={TransitionComponent} />
))(({ theme }) => (
  {
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 0,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

function makeStr(str, q, dM, isShowLink) {
  var res = [];

  for (let index = 0; index < str.length; index++) {
    let el = str[index];
    let st = {}
    if (el.arp_crf == '1')
      st = { color: '#fb9f28!important' }
    console.log(st);
    q += 1;
    if (dM)
      res.push(<StyledTreeItemd sx={st} key={"so" + index} nodeId={el.id} label={isShowLink ? el.name : "****@****.***"}>{el.children && el.children.length > 0 ? makeStr(el.children, q, dM, isShowLink) : null}</StyledTreeItemd>);
    else
      res.push(<StyledTreeItemw sx={st} key={"so" + index} nodeId={el.id} label={isShowLink ? el.name : "****@****.***"}>{el.children && el.children.length > 0 ? makeStr(el.children, q, dM, isShowLink) : null}</StyledTreeItemw>);
  }
  return res;
}

function StructureOverview({ openInfoSB }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const [das, setDas] = React.useState(() => {
    return false
  });

  const [isShowLink, setShowLink] = React.useState(false);


  const copyLink = event => {
    event.persist()

    navigator.clipboard.writeText(das.copyLink)
      .then(() => {

      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    openInfoSB();
  }

  const showLink = event => {
    setShowLink(!isShowLink)
  }


  if (!das) {
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getStructure.php", {
      s_key: s_key,
    }).then(res => {
      //console.log(res.data);
      if (res.data != false) {
        setDas(res.data);
      } else {
        //alert(t`Ошибка связи с сервером`);
        localStorage.clear();
        window.location.href = "/"
      }
    }).catch(() => {
      //alert(t`Ошибка связи с сервером`);
      localStorage.clear();
      window.location.href = "/"
    })
  }

  if (das) {

    return (
      <Card sx={{ height: "100%" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={3}>
          <MDBox display="flex">
            <MDTypography variant="h6" fontWeight="medium">
              {t`Структура`}
            </MDTypography>
          </MDBox>
          <MDBox display="flex">
            <MDButton variant="gradient" color="info" onClick={copyLink} fullWidth >{t`реферальная ссылка`}</MDButton>
            <Button onClick={showLink} >{isShowLink ? <VisibilityIconOff /> : <VisibilityIcon />}</Button>
          </MDBox>
        </MDBox>
        <MDBox pt={2}>
          <TreeView
            aria-label="customized"
            defaultExpanded={['2']}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            sx={{ maxHeight: '100vh', flexGrow: 1, width: '100%', overflowY: 'auto', overflowX: 'auto' }}
          >
            {darkMode ?
              <StyledTreeItemd
                nodeId={das.str.id}
                label={isShowLink ? das.str.name : "****@****.***"}>
                {das.str.children && das.str.children.length > 0 ? makeStr(das.str.children, 0, true, isShowLink) : null}
              </StyledTreeItemd> :
              <StyledTreeItemw
                nodeId={das.str.id}
                label={isShowLink ? das.str.name : "****@****.***"}>
                {das.str.children && das.str.children.length > 0 ? makeStr(das.str.children, 0, false, isShowLink) : null}
              </StyledTreeItemw>}
          </TreeView>
        </MDBox>

      </Card>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default StructureOverview;

/* 7
4
3
2
1
0.5
0.5
0.5
0.5
0.5
0.5 */