/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import Select from '@mui/material/Select';
import axios from 'axios';
import Load from "../../../examples/Load";
import { url_serversq } from "../../../context/var";
import { Grid, InputLabel, MobileStepper } from "@mui/material";
import { ArrowDropDownCircleRounded, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useMaterialUIController } from "../../../context";
import MDButton from "../../../components/MDButton";
import { t } from "ttag";

function ListP({ bal, das, packs, actionUPD, group }) {
  const [controller, dispatch] = useMaterialUIController();
  const [gr, setGr] = useState(() => { return 2 });
  const [isload, setLoad] = useState(() => { return false });
  const [te, setT] = useState(() => { return packs[0] });
  const [ptype, setPtype] = useState(() => { return te.id || null });
  const [btype, setBtype] = useState(() => { return 1 });
  const [activeStep, setActiveStep] = useState(() => { return 0 });
  const [prop, setProp] = useState(() => {
    return {
      api_key: "",
      secret: "",
      mta: "",
      auto_prolong: 0,
      balance: false,
    }
  })

  useEffect(() => {
    if (!ptype) {
      setActiveStep(0);
      setPtype(te.id)
    }
  });

  const handleChange = (value) => {
    setPtype(value);
    setT(packs.find(el => el.id == value));
    setActiveStep((prevActiveStep) => 1);
  };
  const handleChangeB = (event) => {
    setBtype(event.target.value);
    // console.log(event.target.value);
  };

  const changeInputProp = event => {
    event.persist()
    let val = event.target.value;
    setProp(prev => {
      return { ...prev, [event.target.name]: val, }
    })
  }

  const getBalance = () => {
    if (prop.api_key == "" || prop.secret == "") {
      alert(t`Пожалуйста заполните все данные`);
    } else {
      let s_key = localStorage.getItem("s_key");
      let req = { ...prop, s_key: s_key }
      setLoad(true);
      axios.post(url_serversq + "/getBall.php", req).then(res => {
        //console.log(res.data);
        if (res.data !== false) {
          setProp(prev => {
            return { ...prev, balance: res.data.balance, }
          })
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
          setLoad(false);
        } else {
          alert(t`Ошибка добавления, неправильно введенные данные`);
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })
    }
  }

  const handleSave1 = () => {

    if (ptype == "" || prop.api_key == "" || prop.secret == "" || (prop.ida == "" && ptype === 1)) {
      alert(t`Пожалуйста заполните все данные`);
    } else {
      let s_key = localStorage.getItem("s_key");
      let req = { ...prop, s_key: s_key, ptype: ptype, btype: btype }
      setLoad(true);
      axios.post(url_serversq + "/addpack.php", req).then(res => {

        if (res.data !== false) {
          alert(res.data);
          window.location.href = "/dashboard";
          //actionUPD(false);
          //setLoad(false);

        } else {
          alert(t`Ошибка добавления, неправильно введенные данные`);
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })
    }
  }

  const handleSave2 = () => {

    if (ptype == "" || prop.mta == "") {
      alert(t`Пожалуйста заполните все данные`);
    } else {
      let s_key = localStorage.getItem("s_key");
      let req = { ...prop, s_key: s_key, ptype: ptype }
      setLoad(true);
      axios.post(url_serversq + "/addpack.php", req).then(res => {

        if (res.data !== false) {
          alert(res.data);
          window.location.href = "/dashboard";
          //actionUPD(false);
          //setLoad(false);
        } else {
          alert(t`Ошибка добавления, неправильно введенные данные`);
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })
    }
  }


  const handleNext = () => {
    if (gr == 1) {
      if (activeStep === 3) {
        handleSave1();
      }
      else if (activeStep === 1) {
        getBalance();
      }
      else if (activeStep === 2) {
        if (1 * prop.balance >= 1 * te.min_dep && 1 * bal >= (prop.balance / 100) * te.lic_per)
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        else
          alert(t`Недостаточно средств для подключения`);
      } else
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (gr == 2) {
      if (activeStep === 1) {
        handleSave2();
      } else
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep != 0)
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    else
      window.location.href = "/dashboard";

  };


  if (!isload) {
    let u = false;
    var selpr = [];
    selpr.push(
      <Grid container p={1} sx={{ backgroundColor: '#c3e4ff3b' }}>
        <Grid item xs={2} lg={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right', cursor: 'pointer' }} >
        </Grid>
        <Grid item xs={5} sm={2} lg={2} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
          <MDTypography variant="button" fontWeight="medium">
            {t`Название`}
          </MDTypography>
        </Grid>
          <Grid item xs={4} sm={2} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
          <MDTypography variant="button" component="h1" fontWeight="medium" textAlign="center" ml={1} lineHeight={1} >
            {t`Минимальный депозит`}
          </MDTypography>
        </Grid>
        <Grid item xs={4} sm={2} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
          <MDTypography variant="button" fontWeight="medium">
            {t`Тип счета`}
          </MDTypography>
        </Grid>
        <Grid item xs={4} sm={2} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
          <MDTypography variant="button" fontWeight="medium">
            {t`Плечо`}
          </MDTypography>
        </Grid>
        <Grid item xs={11} sm={12} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left', cursor: 'pointer' }} >
          <MDTypography variant="button" fontWeight="medium">

          </MDTypography>
        </Grid>
        <Grid item xs={1} lg={2} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left', cursor: 'pointer' }} >
          <MDTypography variant="button" fontWeight="medium">

          </MDTypography>
        </Grid>
      </Grid>)
    packs.forEach(element => {
      // console.log(element.icon);
      u = !u;
      if (element.gr == gr) {
        selpr.push(<Grid key={element.id} container p={1} sx={{ backgroundColor: u ? '#c3e4ff3b' : 'transparent' }}>
          <Grid item xs={3} sm={2} lg={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right', cursor: 'pointer' }} >
            <MDBox
              justifyContent="center" alignItems="center" width="4rem" height="4rem" shadow="md" borderRadius="lg"
              sx={{ backgroundImage: () => element.icon && ` url(${element.icon})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}            >
            </MDBox>
          </Grid>
          <Grid item xs={5} sm={2} lg={2} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
            <MDTypography variant="h5" fontWeight="medium">
              {element.name}
            </MDTypography>
          </Grid>
          <Grid item xs={4} sm={2} lg={1} sx={{ minHeight: '2em', alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
            <MDTypography variant="button" component="h1" fontWeight="medium" textAlign="center" ml={1} lineHeight={1} >
              {number_format(element.min_dep)} $
            </MDTypography>
          </Grid>
          <Grid item xs={4} sm={2} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
            <MDTypography variant="caption" fontWeight="medium">
              {element.acc_type}
            </MDTypography>
          </Grid>
          <Grid item xs={4} sm={2} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} >
            <MDTypography variant="caption" fontWeight="medium">
              1:{element.plecho}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left', cursor: 'pointer' }} >
            <MDButton variant="gradient" color="info" size="normal" fullWidth onClick={() => handleChange(element.id)}>
              {t`Подключить`}
            </MDButton>

          </Grid>
          <Grid item xs={12} lg={2} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left', cursor: 'pointer' }} >


          </Grid>
        </Grid>
        );

      }
    });

    var steps = null;
    if (gr == 1)
      steps = [
        {
          label: t`Параметры`,
          description: (
            <MDBox key={'d2'} p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" pb={2} justifyContent="space-between">
                    <Grid container spacing={3} justifyContent="center" >

                      {selpr}
                    </Grid>
                  </MDBox>

                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={8} >
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDTypography p={1} variant="button" fontWeight="bold" fullWidth alignItems="center">
                          {t`Параметры CF`} &nbsp;&nbsp;
                        </MDTypography>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" fontWeight="normal" width='50%'>
                            {t`Минимальный баланс:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="numbers" variant="standard" width='50%' name="secret" disable={true} value={te.min_dep + ' USDT'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Размер CF:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" variant="standard" width='50%' name="secret" disable={true} value={te.lic_per + ' %'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Размер прибыли:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" variant="standard" width='50%' name="secret" disable={true} value={te.prof_per + ' %'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} justifyContent="center">
                          <MDTypography p={1} variant="button" fontWeight="normal"  >
                            <div className="question-text" dangerouslySetInnerHTML={{ __html: te.description }} />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          ),
        },
        {
          label: t`Настройка API`,
          description: (
            <MDBox key={'d1'} p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" flexDirection='column' alignItems='stretch' >
                    <MDBox display="flex" py={1} pr={2} justifyContent="space-between">
                      <MDTypography p={1} variant="button" fontWeight="bold" >
                        {t`Выбирите Биржу:`} &nbsp;&nbsp;
                      </MDTypography>
                      <Select
                        value={btype}
                        label="Биржа"
                        onChange={handleChangeB}
                        style={{ width: '100%' }}
                      >
                        <MenuItem key={"b1"} value={1}>Finandy</MenuItem>
                        <MenuItem key={"b2"} value={2}>Binance</MenuItem>
                      </Select>
                    </MDBox>

                    <MDTypography variant="button" gutterBottom>
                      {t`Введите параметры API из кабинета биржи`}
                    </MDTypography>

                    <MDBox display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Api key: &nbsp;&nbsp;
                      </MDTypography>
                      <MDInput type="text" variant="standard" fullWidth name="api_key" disable={prop.balance} value={prop.api_key} onChange={changeInputProp} />
                    </MDBox>
                    <MDBox display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Secret: &nbsp;&nbsp;
                      </MDTypography>
                      <MDInput type="text" variant="standard" fullWidth name="secret" disable={prop.balance} value={prop.secret} onChange={changeInputProp} />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox >),
        },
        {
          label: t`Параметры`,
          description: (
            <MDBox key={'d2'} p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" pb={2} justifyContent="space-between">
                    <Grid container spacing={3} justifyContent="center" >
                      {selpr}
                    </Grid>
                  </MDBox>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} >
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDTypography p={1} variant="button" fontWeight="bold" fullWidth alignItems="center">
                          {t`Параметры CF`} &nbsp;&nbsp;
                        </MDTypography>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" fontWeight="normal" width='50%'>
                            {t`Минимальный баланс:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="numbers" variant="standard" width='50%' name="secret" disable={true} value={te.min_dep + ' USDT'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Размер CF:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" variant="standard" width='50%' name="secret" disable={true} value={te.lic_per + ' %'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Размер прибыли:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" variant="standard" width='50%' name="secret" disable={true} value={te.prof_per + ' %'} />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDTypography p={1} variant="button" fontWeight="bold" fullWidth alignItems="center">
                          {t`Расчет`}&nbsp;&nbsp;
                        </MDTypography>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Баланс:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" width='50%' error={1 * prop.balance < 1 * te.min_dep} variant="standard" fullWidth name="secret" disable={true} value={(1 * prop.balance).toFixed(2) + ' USDT'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Сумма CF:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput width='50%' type="text" error={1 * bal < (prop.balance / 100) * te.lic_per} variant="standard" fullWidth name="secret" disable={true} value={((prop.balance / 100) * te.lic_per).toFixed(2) + ' USDT'} />
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} >
                          <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                            {t`Минимальный баланс завершения CF:`} &nbsp;&nbsp;
                          </MDTypography>
                          <MDInput type="text" variant="standard" width='50%' fullWidth name="secret" disable={true} value={(1 * prop.balance + (prop.balance / 100) * te.prof_per).toFixed(2) + ' USDT'} />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          ),
        },
        {
          label: t`Подтверждение`,
          description: (
            <MDBox key={'d3'} p={2}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography variant="button" fontWeight="bold" textAlign="center">
                      {t`При завершении CF с вашего счет будет автоматически списана стоимость следующего CF.`}
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="bold" textAlign="center">
                      {t`Если на счете не достаточно средств для списания, CF автоматически деактивируется.`}
                    </MDTypography>
                  </MDBox>
                  {btype === 1 ?
                    <MDBox
                      borderRadius="lg"
                      p={3}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDTypography p={1} variant="button" fontWeight="bold" fullWidth alignItems="center">
                        {t`Настройки`}&nbsp;&nbsp;
                      </MDTypography>
                      {te.p1 ? <MDBox display="flex" py={1} pr={2} >
                        <MDTypography variant="button" width="50%" fontWeight="normal" >
                          {t`Размер позиции:`} &nbsp;&nbsp;
                        </MDTypography>
                        <MDTypography type="text" variant="standard" width="50%" fullWidth  >{((prop.balance / 100) * te.p1 * 10).toFixed(2)}</MDTypography>
                      </MDBox> : null}

                      {te.description ? <MDBox display="flex" py={1} pr={2} >
                        <MDTypography variant="button" fontWeight="normal" >
                          {te.description}
                        </MDTypography>
                      </MDBox> : null}
                      {te.copy_key ?
                        <MDBox>
                          <MDBox display="flex" py={1} pr={2} >
                            <MDTypography variant="button" width="50%" fontWeight="normal" >
                              {t`ID Мастер-трейдер:`} &nbsp;&nbsp;
                            </MDTypography>
                            <MDTypography type="text" variant="standard" width="50%" fullWidth >{te.copy_key}</MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} >
                            <MDTypography variant="button" width="50%" fontWeight="normal" >
                              {t`ID Вашего кабинета:`} &nbsp;&nbsp;
                            </MDTypography>
                            <MDInput type="text" variant="standard" width="50%" fullWidth name="ida" value={prop.ida} onChange={changeInputProp} />
                          </MDBox>
                        </MDBox> : null}
                      {te.pic ?
                        <MDBox display="flex" py={1} pr={2}>
                          <a href={te.pic} target="_blank" >
                            <MDBox component="img" src={te.pic} alt="Load..." width="100%" />
                          </a>
                        </MDBox> : null}
                    </MDBox>
                    : null}
                </Grid>
              </Grid>
            </MDBox >
          ),
        },
      ];

    if (gr == 2)
      steps = [
        {
          label: 'Options',
          description: (
            <MDBox key={'d2'} >
              <Grid container >
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" pb={2} justifyContent="space-between">
                    <Grid container justifyContent="center" >
                      {selpr}
                    </Grid>
                  </MDBox>


                </Grid>
              </Grid>
            </MDBox>
          ),
        },
        {
          label: t`Подтверждение`,
          description: (
            <MDBox key={'d3'} p={2}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={8} >
                  <MDBox component="form" role="form">
                    <MDBox mb={2} style={{ display: "flex", alignItems: "center", }} >
                      <MDInput type="text" label={t`Ссылка для подключения копирования`} fullWidth value={te.copy_key} disable /> &nbsp;&nbsp;&nbsp;
                      <MDButton variant="gradient" color="info" href={te.copy_key} target="_blank"  >{t`подключить копирование`} </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} mb={3}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      {t`Номер счета:`} &nbsp;&nbsp;
                    </MDTypography>
                    <MDInput type="text" variant="standard" fullWidth name="mta" value={prop.mta} onChange={changeInputProp} />
                  </MDBox>

  
                  <MDBox
                    borderRadius="lg"
                    p={3}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox display="flex" py={1} pr={2} >
                      <MDTypography p={1} width='50%' variant="button" fontWeight="bold" fullWidth alignItems="center">
                        {t`Параметры подключения`} &nbsp;&nbsp;
                      </MDTypography>
                      <MDTypography p={1} width='50%' variant="button" fontWeight="bold" fullWidth alignItems="center">
                        {te.name} &nbsp;&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} pr={2} >
                      <MDTypography p={1} variant="button" fontWeight="normal" width='50%'>
                        {t`Тип счета:`} &nbsp;&nbsp;
                      </MDTypography>
                      <MDInput type="text" variant="standard" width='50%' disable={true} value={te.acc_type} />
                    </MDBox>
                    <MDBox display="flex" py={1} pr={2} >
                      <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                        {t`Кредитное плечо:`} &nbsp;&nbsp;
                      </MDTypography>
                      <MDInput type="text" variant="standard" width='50%' disable={true} value={'1:' + te.plecho} />
                    </MDBox>
                    <MDBox display="flex" py={1} pr={2} >
                      <MDTypography p={1} variant="button" width='50%' fontWeight="normal" >
                        {t`Минимальный депозит:`} &nbsp;&nbsp;
                      </MDTypography>
                      <MDInput type="text" variant="standard" width='50%' disable={true} value={te.min_dep + ' $'} />
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} mb={3} justifyContent="center" >
                    <MDBox component="img" src={te.copy_img} mt={2} maxWidth={'100%'} />

                  </MDBox>

                </Grid>
              </Grid>
            </MDBox >
          ),
        },
      ];

    return (
      <MDBox>
        <Grid container >
          <Grid item xs={12} md={12}>
            <Card pt={2} style={{ justifyContent: 'space-between' }} >

              <MDBox >
                {steps[activeStep].description}
              </MDBox>

              {activeStep > 0 ?

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <MobileStepper
                    variant="none"
                    steps={gr == 1 ? 4 : 2}
                    position="static"
                    activeStep={activeStep}
                    sx={{ flexGrow: 1 }}
                    nextButton={
                      <MDButton variant="gradient" color="info" size="large" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? t`сохранить` : ''}
                        {activeStep === 0 ? t`подключить` : ''}
                        <KeyboardArrowRight />
                      </MDButton>


                    }
                    backButton={
                      <MDButton variant="gradient" color="info" size="large" onClick={handleBack}>
                        <KeyboardArrowLeft />
                        {activeStep != 0 ? t`назад` : ''}
                      </MDButton>}
                  />
                </div> : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );

  } else { return (<Load />); }
}

function number_format(number, decimals, dec_point, thousands_sep) {  // Format a number with grouped thousands
  var i, j, kw, kd, km;

  if (isNaN(decimals = Math.abs(decimals))) {
    decimals = 2;
  }
  if (dec_point == undefined) {
    dec_point = ",";
  }
  if (thousands_sep == undefined) {
    thousands_sep = ".";
  }

  i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

  if ((j = i.length) > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  km = (j ? i.substr(0, j) + thousands_sep : "");
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
  kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

  return km + kw + kd;
}

export default ListP;
