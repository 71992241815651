/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDSnackbar from "../../components/MDSnackbar";
import axios from 'axios';
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ReportsBarChart from "../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Load from "../../examples/Load";

// Data 
import reportsBarChartData from "../../layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "../../layouts/dashboard/data/reportsLineChartData";
import { url_serversq } from "../../context/var";

// Dashboard components
import Projects from "../../layouts/dashboard/components/Projects";
import StructureOverview from "./components/StructureOverview";
import ChartDep from "./components/ChartDep";
import ChartProf from "./components/ChartProf";

import { t, jt } from 'ttag';

function Dashboard() {

  const [das, setDas] = useState(() => {
    return false
  });
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [infoSB, setInfoSB] = useState(false);

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t`Информация`}
      content={t`Реферальная ссылка скопирована в буфер обмена`}
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
 
  if (!das) {
    let locale = localStorage.getItem("lang") || 'ru';
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getdas.php", {
      s_key: s_key, locale: locale,
    }).then(res => {
      // localStorage.clear();
      //console.log(res.data);

      if (res.data != false) {
        setDas(res.data);
      } else {
        alert(t`Ошибка связи с сервером`);
        localStorage.clear();
        window.location.href = "/"
      }
    }).catch(() => {
      alert(t`Ошибка связи с сервером`);
      localStorage.clear();
      window.location.href = "/"
    })
  }

  if (das) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="dark"
                        icon="money"
                        title="USDT"
                        count={parseFloat(das.bonus).toFixed(2)}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Доступный баланс`,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    {/* <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="success"
                        icon="store"
                        title="USDT"
                        count={das.profit}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Профит`,
                        }}
                      />
                    </MDBox> */}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="primary"
                        icon="person"
                        title={t`количество`}
                        count={das.totalref}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: t`Рефералы`,
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <Projects das={das.packs} bal={das.bonus} packs={das.products} actionUPD={setDas} />
                  </Grid>
                 {/*  <Grid item xs={12}>
                    <ChartProf das={das.stats} />
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <StructureOverview das={das.strcture} openInfoSB={openInfoSB} />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {renderInfoSB}
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Dashboard;
