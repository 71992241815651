/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useParams } from "react-router-dom";
import React, { createRef, useRef, useState } from "react";
import axios from 'axios';
import validator from 'validator';

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from '@mui/icons-material/Visibility';

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import Load from "../../../examples/Load";

// Authentication layout components
import CoverLayout from "../../../layouts/authentication/components/CoverLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-up-cover.jpeg";
import { url_serversq, sitekey } from "../../../context/var";
import { Icon } from "@mui/material";
import { t } from "ttag";
import ReCAPTCHA from "react-google-recaptcha";
import { addLocale, useLocale } from 'ttag';
import LangMenu from "../../../examples/Navbars/DashboardNavbar/LangMenu";

function getLocale() {

  var language = window.navigator ? (window.navigator.language ||
    window.navigator.systemLanguage ||
    window.navigator.userLanguage) : "en";
  language = language.substring(0, 2);

  if (!(language == "en" || language == "ru" || language == "ua")) language = "en";

  return localStorage.getItem("lang") || language;
}

function Cover() {

  const { referal } = useParams();
  const [isLoad, setLoad] = useState(false);
  const [msg, setMsg] = useState(false);
  const captchaRef = useRef(null)

  const [idref, setIdref] = useState(() => { return -1 });

  const [register, setRegister] = useState(() => {
    return {
      email: "",
      password: "",
      password2: "",
      igree: false,
      eKey: false,
      eKeye: false,
    }
  });
  const locale = getLocale();

  if (locale !== 'ru') {
    const translationsObj = require(`../../../../i18n/${locale}.po.json`);
    addLocale(locale, translationsObj);
  }

  const changeInputRegister = event => {
    event.persist()
    if (event.target.name == "igree") {
      setRegister(prev => {
        return {
          ...prev,
          [event.target.name]: !register.igree,
        }
      })
    } else {
      setRegister(prev => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        }
      })
    }
  }

  const submitChackin = (event) => {
    event.preventDefault();
    let token = captchaRef.current.getValue();

    if (register.eKey === false) {
      if (!validator.isEmail(register.email)) {
        setMsg("You did not enter email")
      } else {
        //let token = captchaRef.current.getValue();
        setLoad(true);
        setMsg("");
        axios.post(url_serversq + "/sendkey.php", {
          email: register.email,
          rcp: token,
        }).then(res => {

          if (res.data == 'recaptcha') {
            setLoad(false);
            setMsg("Captcha error");
          } else if (res.data !== false) {
            setLoad(false);

            setRegister(prev => {
              return {
                ...prev,
                eKey: "",
                eKeye: res.data,
              }
            })
          } else {
            setLoad(false);
            setMsg("There is already a user with this email");
          }
        }).catch(() => {
          setLoad(false);
          setMsg("An error occurred on the server");
        })
      }
    } else {
      if (!validator.isEmail(register.email)) {
        setMsg("You did not enter email")
      } else if (register.password !== register.password2) {
        setMsg("Repeated password incorrectly")
      } else if (!register.igree) {
        setMsg(t`Вы не отметели согласие`)
      } else if (register.eKey != register.eKeye) {
        setMsg(t`Код подтверждения не совпадает`)
      } else if (!validator.isStrongPassword(register.password, { minSymbols: 0 })) {
        setMsg("Password must consist of one lowercase, uppercase letter and number, at least 8 characters")
      } else {
        document.getElementById("subBTN").setAttribute('disabled', true);
        axios.post(url_serversq + "/reg.php", {
          username: register.username,
          email: register.email,
          password: register.password,
          eKey: register.eKey,
          referal: idref,

        }).then(res => {

          if (res.data != false) {
            setMsg(res.data);
            window.location.href = "/"
          } else {
            setMsg("There is already a user with this email");
            document.getElementById("subBTN").removeAttribute('disabled', false);
          }
        }).catch(() => {
          setMsg("An error occurred on the server");
          document.getElementById("subBTN").removeAttribute('disabled', false);

        })

      }
    }
  }

  if (idref < 0) {
    axios.post(url_serversq + "/getref.php", {
      reflink: referal,
    }).then(res => {

      if (res.data !== false) {
        setIdref(res.data);
      } else {
        setMsg(t`Неправильная реферальная ссылка`);
        window.location.href = "/"
      }
    }).catch(() => {
      setMsg(t`Регистрация невозможна`);
      window.location.href = "/"
    })
  }
  let iekey = [];
  if (register.eKey !== false)
    iekey.push(<MDInput key={"kk1"} type="text" label="Email Key" variant="standard" fullWidth name={"eKey"} value={register.eKey} onChange={changeInputRegister} />);

  let smsg = null;
  if (msg)
    smsg = (<MDTypography variant="h6" fontWeight="medium" color="#f44335!important" mt={1}>
      {msg}
    </MDTypography>);

  let butt = (
    <MDButton variant="gradient" id="subBTN" color="info" fullWidth onClick={submitChackin} >
      {t`зарегистрироваться`}
    </MDButton>
  );

  if (isLoad)
    butt = (<Load />);

  const show_hide_password = (t) => {
    var input = document.getElementById(t);
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
    } else {
      input.setAttribute('type', 'password');
    }
    return false;
  }
  if (idref > 0)
    return (
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {t`Регистрация`}
            </MDTypography>
          </MDBox>
          <MDBox pb={1} display="flex" justifyContent="space-evenly" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium" >
              {t`Сменить язык`}
            </MDTypography>
            <LangMenu />
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput type="email" label="Email" variant="standard" fullWidth name="email" value={register.email} onChange={changeInputRegister} />
              </MDBox>
              <MDBox mb={2} style={{ display: "flex", alignItems: "center", }}>
                <MDInput type="password" label="Password" variant="standard" fullWidth name="password" id="password1" value={register.password} onChange={changeInputRegister} />
                <Link onClick={() => show_hide_password('password1')} >   <VisibilityIcon /></Link>

              </MDBox>
              <MDBox mb={2} style={{ display: "flex", alignItems: "center", }}>
                <MDInput type="password" label="Repeat password" variant="standard" fullWidth name="password2" id="password2" value={register.password2} onChange={changeInputRegister} />
                <Link onClick={() => show_hide_password('password2')} >   <VisibilityIcon /></Link>

              </MDBox>
              <MDBox mt={2} mb={1}>
                <ReCAPTCHA
                  sitekey={sitekey}
                  ref={captchaRef}
                  theme={localStorage.getItem('darkMode') === "true" ? 'dark' : 'light'}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox name="igree" checked={register.igree} onChange={changeInputRegister} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  fontSize="0.75rem"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  {t`Я принимаю условия`} <a href={"https://sonattrade.com/privacy_policy_"+locale+".pdf"}>{t`Клиентского соглашения и Политики конфиденциальности`}</a> {t`и даю согласие на обработку персональных данных. Я подтверждаю что мне есть 18 лет.`}
                </MDTypography>

              </MDBox>
              <MDBox mt={4} mb={1}>
                {iekey}
              </MDBox>
              <MDBox mt={4} mb={1}>
                {smsg}
              </MDBox>
              <MDBox mt={4} mb={1}>
                {butt}
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {t`Уже есть аккаунт?`}
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    {t`Войти`}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    );
  else
    return null;
}

export default Cover;
