import React, { useState } from 'react';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import StatFX from './statFX';

function Calculator() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <StatFX />
            <Footer />
        </DashboardLayout>
    );

}


export default Calculator;

