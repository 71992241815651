/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDSnackbar from "../../components/MDSnackbar";
import axios from 'axios';
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Load from "../../examples/Load";

// Data 
import { url_serversq, number_format } from "../../context/var";


import { t, jt } from 'ttag';
import Requests from "../billing/components/Requests";
import BuyBonus from "./components/BuyBonus";
import Discount from "./components/Discount";

function Ise() {

  const [das, setDas] = useState(() => {
    return false
  });

  if (!das) {
    let locale = localStorage.getItem("lang") || 'ru';
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getise.php", {
      s_key: s_key, locale: locale,
    }).then(res => {
      // localStorage.clear();
      //console.log(res.data);

      if (res.data != false) {
        var dd = res.data
        //sortByInd(dd.dtl)
        setDas(dd);
      } else {
        //alert(t`Ошибка связи с сервером`);
        localStorage.clear();
        window.location.href = "/"
      }
    })
  }

  if (das) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <Discount das={das} setDas={setDas} />
                  </Grid>
                  <Grid item xs={12} >
                    <BuyBonus das={das} setDas={setDas} />
                  </Grid>
                  <Grid item xs={12} >
                    <Requests das={das} setDas={setDas} />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Ise;


