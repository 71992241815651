import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocale } from 'ttag';

export default function LangMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getLocale() {
    return localStorage.getItem("lang") || 'ru';
  }
  
  function saveLocale(locale) {
    localStorage.setItem("lang", locale);
    window.location = window.location.href;
  }


  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {getLocale()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>{saveLocale("en"); handleClose();}}>English</MenuItem>
        <MenuItem onClick={()=>{saveLocale("ua"); handleClose();}}>Українська</MenuItem>
        <MenuItem onClick={()=>{saveLocale("ru"); handleClose();}}>Русский</MenuItem>
      </Menu>
    </div>
  );
}