/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../dashboard/components/Projects/data";
import { url_serversq, number_format, getPool } from "../../../../context/var";
import MDButton from "../../../../components/MDButton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, Paper, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useMaterialUIController } from "../../../../context";
import { t, jt } from 'ttag';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import MDInput from "../../../../components/MDInput";
import { margin } from "@mui/system";


function BuyBonus({ das, setDas }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  var stwd = darkMode ? { color: '#fff' } : { color: '#212121' }

  const [amount, setAmount] = useState(() => { return 0 });
  const [ekod, setEkod] = useState(() => { return "" });
  const [isekod, setIsekod] = useState(() => { return false });

  const handleSend = (event) => {
    //console.log(3);
    event.preventDefault();
    if ((parseFloat(amount)) > parseFloat(das.bonus)) {
      alert(t`Сумма превышает допустимую`);
      return
    }
    if (parseFloat(amount) < 20) {
      alert(t`Minimum amount 20`);
      return
    }
    if (!isekod) {
      setIsekod(true)
      let s_key = localStorage.getItem("s_key");
      axios.post(url_serversq + "/sendkey1.php", {
        s_key: s_key
      }).then(res => {
        if (res.data !== false) {
        } else {
          alert("An error occurred on the server");
        }
      }).catch(() => {
        alert("An error occurred on the server");
      })
    }
  };

  const getSum = (sum) => {
    if (sum <= 100)
      return sum - (sum * ((25 - parseFloat(das.disc) * (25 / 100)) / 100));
    if (sum > 100 && sum <= 600)
      return sum - (sum * ((50 - parseFloat(das.disc) * (50 / 100)) / 100));
    if (sum > 600 && sum <= 2000)
      return sum - (sum * ((75 - parseFloat(das.disc) * (75 / 100)) / 100));
    if (sum > 2000) {
      let s = sum - (sum * ((100 - (100 - parseFloat(das.disc)) * (100 / 100)) / 100))
      return ((100 - (100 - parseFloat(das.disc)) * (100 / 100)) / 100) == 0 ? 50 : s;
    }
  }

  const handleSave = (event) => {
    //console.log(3);
    event.preventDefault();
    if ((parseFloat(amount)) > parseFloat(das.bonus)) {
      alert(t`Сумма превышает допустимую`);
      return
    }
    if (parseFloat(amount) < 20) {
      alert(t`Minimum amount 20`);
      return
    }

    if (isekod)
      if ((parseFloat(amount)) > parseFloat(das.bonus)) {
        alert(t`Сумма превышает допустимую`);
        setDas(prev => { return { ...prev, oper: 0, id_pool: 0 } });
      } else {
        let s_key = localStorage.getItem("s_key");
        let reqa = { ekod: ekod, amount: amount, amounts: getSum(amount), oper: 1, isekab: das.isekab, s_key: s_key }
        axios.post(url_serversq + "/reqise.php", reqa).then(res => {
          if (res.data !== false) {
            alert(res.data);
            setDas(false);
          } else {
            alert(t`Ошибка добавления, неправильно введенные данные`);
            setDas(false);
          }
        }).catch(() => {
          alert("An error occurred on the server");
          setDas(false);
        })

      }
  };

  return (
    <Card >
      <Grid container p={0} spacing={2}>
        <Grid item xs={1} md={3} >

        </Grid>
        <Grid item xs={10} md={6}>
          <MDTypography variant="h4" mt={4} textAlign="center">{t`Заявка на приобретение ISE Bonus `}</MDTypography>
          <Card style={{ marginTop: "15px", marginBottom: "15px", padding: "25px" }} >

            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} >
                <MDTypography variant="h6" textAlign="end">{t`Доступный баланс`}</MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDInput type="text" fullWidth value={das.bonus} disable={true} />
              </Grid>

              <Grid item xs={6} >
                <MDTypography variant="h6" textAlign="end">{t`Username кабинета ISE`}</MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDInput type="text" fullWidth value={das.isekab} onChange={(event) => setDas(prev => { return { ...prev, isekab: event.target.value } })} />
              </Grid>

              <Grid item xs={6} >
                <MDTypography variant="h6" textAlign="end">{t`Введите сумму бонусов которую хотите купить`}</MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDInput type="text" fullWidth value={amount} onChange={(event) => { if (!isekod) setAmount(event.target.value) }} />
              </Grid>

              <Grid item xs={6} >
                <MDTypography variant="h6" textAlign="end">{t`Сумма для оплаты`}</MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDInput type="text" fullWidth value={getSum(amount)} disable={true} />
              </Grid>

              <Grid item xs={4} >
                <MDTypography variant="h6" textAlign="end">{t`E-Mail код`}</MDTypography>
              </Grid>
              <Grid item xs={4} >
                <MDInput type="text" fullWidth value={ekod} onChange={(event) => { if (isekod) setEkod(event.target.value) }} />
              </Grid>
              <Grid item xs={4} >
                {!isekod ? <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={handleSend} >{t`получить`} </MDButton> :
                  <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={handleSave} >{t`сохранить`} </MDButton>}
              </Grid>

            </Grid>
          </Card>
        </Grid >

      </Grid >


    </Card >

  );


}

export default BuyBonus;
