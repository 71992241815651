/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

// Material Dashboard 2 React example components
import TimelineItem from "../../../../examples/Timeline/TimelineItem";


import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import Load from "../../../../examples/Load";
import axios from "axios";
import { url_serversq } from "../../../../context/var";
import { useMaterialUIController } from "../../../../context";
import { t } from "ttag";



function PoolInfo({ openInfoSB }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {t`Структура`}
        </MDTypography>
      </MDBox>

    </Card>
  );
}


export default PoolInfo;

/* 7
4
3
2
1
0.5
0.5
0.5
0.5
0.5
0.5 */