/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../dashboard/components/Projects/data";
import { url_serversq, number_format, getPool } from "../../../../context/var";
import MDButton from "../../../../components/MDButton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, Paper, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useMaterialUIController } from "../../../../context";
import { t, jt } from 'ttag';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function Operation({ das, actionUPD }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  var stwd = darkMode ? { color: '#fff' } : { color: '#212121' }


  const handleSwitch = (event) => {
    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, id: event.target.value }
    let ur = event.target.checked ? "/activripool.php" : "/deactivripool.php";
    axios.post(url_serversq + ur, req).then(res => {

      if (res.data !== false) {
        actionUPD(prev => { return { ...prev, ['auto_ri' + event.target.value]: !event.target.checked } });
      } else {
        alert(t`Ошибка`);
      }
    }).catch(() => {
      alert("An error occurred on the server");
    })
  };


  /* useEffect(() => {
    if (!prop.api_key) {
      setActiveStep(0);
      setPtype(t.id)
    }
  }); */

  const [open, setOpen] = useState(() => { return false });
  const [idd, setId] = useState(() => { return false });
  const [valueT, setValueT] = useState(0);

  const handleChangeT = (event, newValue) => {
    let nn='';
    let nv=0;
    if(newValue==0) {
      nn='X2'
      nv=das.poolusdtX2
    }
    if(newValue==1) {
      nn='X1'
      nv=das.poolusdtX1
    }
    if(newValue==2) {
      nn='X3'
      nv=das.poolusdtX3
    }
    if(newValue==3) {
      nn='Mixt'
      nv=das.poolusdtMixt
    }
    actionUPD(prev => { return { ...prev, poolusdtMN: nn, poolusdtM: nv } });
    setValueT(newValue);
  };
  const handleChangeIndex = (index) => {
    setValueT(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOK = () => {
    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, id: idd }

    let ur = "/droppack.php";
    axios.post(url_serversq + ur, req).then(res => {
      if (res.data !== false) {
        actionUPD(false);
      } else {
        alert(t`Ошибка`);
      }
    }).catch(() => {
      alert("An error occurred on the server");
    })
    setOpen(false);
  };
  var ipl = getPool(valueT + 1)
  /*   var mi = []
   var smi = 0
   for (let index = 0; index < das.dtl.length; index++) {
     var row = das.dtl[index];
     if (index < ipl) {
       smi = smi + (parseFloat(row['profpool' + ipl]) * ((parseFloat(das['balanceinpool' + ipl]) / ipl) / 100)) || 0
       mi.push(<TableRow
         key={row.id}
         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
       >
         <TableCell component="th" scope="row" sx={stwd}>
           {row.name}
         </TableCell>
         <TableCell align="right" sx={stwd}>{number_format(parseFloat(das['balanceinpool' + ipl]) / ipl, 5)}</TableCell>
         <TableCell align="right" sx={stwd}>{row.prof}</TableCell>
         <TableCell align="right" sx={stwd}>{number_format(parseFloat(row.prof) * ((parseFloat(das['balanceinpool' + ipl]) / ipl) / 100), 5)}</TableCell>
         <TableCell align="right" sx={stwd}>{number_format(parseFloat(row['profpool' + ipl]) * ((parseFloat(das['balanceinpool' + ipl]) / ipl) / 100), 5)}</TableCell>
       </TableRow>
       )
     }
     if (row.id == -1) {
       smi = smi + (parseFloat(row['profpool' + ipl]) * ((parseFloat(das['balanceinpool' + ipl])) / 100)) || 0
       mi.push(<TableRow
         key={row.id}
         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
       >
         <TableCell component="th" scope="row" sx={stwd}>
           {row.name}
         </TableCell>
         <TableCell align="right" sx={stwd}></TableCell>
         <TableCell align="right" sx={stwd}></TableCell>
         <TableCell align="right" sx={stwd}></TableCell>
         <TableCell align="right" sx={stwd}>{"-" + number_format(parseFloat(row['profpool' + ipl]) * ((parseFloat(das['balanceinpool' + ipl])) / 100), 5)}</TableCell>
       </TableRow>
       )
     }
   } */


  return (
    <Card >
      <Tabs value={valueT} onChange={handleChangeT} centered style={{ marginTop: '2em' }} sx={{
        '& .MuiTabs-indicator': { backgroundColor: '#ffc107c7' },
        '& .MuiTab-root': { color: '#ffc107c7' },
        '& .Mui-selected': { color: '#ffc107c7' },
      }}>
        <Tab label={t`Pool X2`} style={{ height: '3em' }} sx={{ typography: 'h4', }} />
        <Tab label={t`Pool X1`} style={{ height: '3em' }} sx={{ typography: 'h4', }} />
        <Tab label={t`Pool X3`} style={{ height: '3em' }} sx={{ typography: 'h4', }} />
        <Tab label={t`Pool Mixt`} style={{ height: '3em' }} sx={{ typography: 'h4', }} />
      </Tabs>

      <Grid container p={0} spacing={2}>

        <Grid item xs={12} md={8} >
          <Card>
            <Grid container p={0} spacing={2}>
              <Grid item xs={12} md={8} >
                <Grid container pt={2} pb={2} spacing={2}>
                  <Grid item xs={9} >
                    <MDTypography variant="h6" gutterBottom textAlign="end">
                      {t`Баланс в пуле`}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={3} >
                    <MDTypography variant="h6" gutterBottom textAlign="start">
                      {number_format(das['balanceinpool' + ipl])}$
                    </MDTypography>
                  </Grid>

                  <Grid item xs={9} >
                    <MDTypography variant="h6" gutterBottom textAlign="end">
                      {t`Ожидает добавление`}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={3} >
                    <MDTypography variant="h6" gutterBottom textAlign="starts">
                      {number_format(das['winp' + ipl])}$
                    </MDTypography>
                  </Grid>

                  <Grid item xs={9} >
                    <MDTypography variant="h6" gutterBottom textAlign="end">
                      {t`Ожидает вывод`}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={3} >
                    <MDTypography variant="h6" gutterBottom textAlign="start">
                      {number_format(das['woutp' + ipl])}$
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} >
                <MDBox style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "stretch", justifyContent: "space-evenly" }} p={1}>
                  <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={() => actionUPD(prev => { return { ...prev, oper: 1, id_pool: valueT + 1 } })} >{t`добавить`} </MDButton>
                  <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={() => actionUPD(prev => { return { ...prev, oper: 2, id_pool: valueT + 1 } })} >{t`вывести`} </MDButton>
                  <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={() => actionUPD(prev => { return { ...prev, oper: 3, id_pool: valueT + 1 } })} >{t`перенести`} </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} mb={4}>
          <Card>
            <Grid container p={2} spacing={2}>

              <Grid item xs={12} borderBottom={'solid 1px'}>
                <MDTypography variant="h6" gutterBottom textAlign="center">
                  {t`Участие прошлой недели` + ':   ' + number_format(das['pbalanceinpool' + ipl])}$
                </MDTypography>
              </Grid>
              <Grid item xs={12} borderBottom={'solid 1px'}>
                <MDTypography variant="h6" gutterBottom textAlign="center">
                  {t`Профит прошлой недели`}
                </MDTypography>
              </Grid>
              <Grid item xs={6} borderRight={'solid 1px'}>
                <MDTypography variant="h6" gutterBottom textAlign="center">
                  {number_format(das['profpool' + ipl] * (das['pbalanceinpool' + ipl] / 100))}$
                </MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDTypography variant="h6" gutterBottom textAlign="center">
                  {number_format(das['profpool' + ipl])}%
                </MDTypography>
              </Grid>
              {/* <Grid item xs={6} >
                <MDTypography variant="h6" gutterBottom textAlign="end">
                  {t`Автореинвест`}
                </MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDBox width="8em" textAlign="start">
                  <div>
                    <Switch
                      value={ipl}
                      checked={das['auto_ri' + ipl] == 1 ? true : false}
                      onChange={handleSwitch}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>

                </MDBox>
              </Grid> */}
            </Grid>


          </Card>
        </Grid>



      </Grid>

    </Card>

  );


}

export default Operation;
