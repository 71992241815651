import MDBox from "../../components/MDBox";
import loadgif from "../../assets/images/load.gif";
function Load() {
  return (

    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
    }}>
       <MDBox component="img" src={loadgif} alt="Load..." width="150px"  />
    </div >

  );
}

export default Load;
