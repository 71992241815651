/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { url_serversq } from "../../../../context/var";
import DataTable from "../../../../examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "../Transaction";
import data from "./data";
import { t, jt } from 'ttag';

function Requests({ das, setDas }) {
  const [open, setOpen] = useState(() => { return false });
  const [idd, setId] = useState(() => { return false });

  const { columns, rows } = data({ das, setId, setOpen });

  const handleOK = () => {
    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, id: idd }

    let ur = "/dropreq.php";
    axios.post(url_serversq + ur, req).then(res => {
      if (res.data !== false) {
        setDas(false);
      } else {
        alert(t`Ошибка`);
      }
    }).catch(() => {
      alert("An error occurred on the server");
    })
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {t`Заявки`}
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={true}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle sx={{ color: "#344767" }}>{t`Внимание`}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#344767" }}>
            {t`Вы уверенны что хотите отменить заявку?`}
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t`Отмена`}</Button>
          <Button onClick={handleOK}>{t`Я уверен(а)`}</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default Requests;
