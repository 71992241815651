/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../dashboard/components/Projects/data";
import { url_serversq, number_format, getPool } from "../../../../context/var";
import MDButton from "../../../../components/MDButton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useMaterialUIController } from "../../../../context";
import { t, jt } from 'ttag';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import MDInput from "../../../../components/MDInput";
import { margin } from "@mui/system";


function MoveDep({ das, setDas }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  var stwd = darkMode ? { color: '#fff' } : { color: '#212121' }

  const [amount, setAmount] = useState(() => { return 0 });
  const [ekod, setEkod] = useState(() => { return "" });
  const [isekod, setIsekod] = useState(() => { return false });

  const handleSend = (event) => {
    //console.log(3);
    event.preventDefault();
    if (parseFloat(amount) > parseFloat(das['balanceinpool' + getPool(das.id_pool)])) {
      alert(t`Сумма превышает допустимую`);
      return
    }

    if ((parseFloat(amount)) < 100 && (das['balanceinpool' + getPool(das.id_pool)] == 0 || das['balanceinpool' + getPool(das.id_pool)] == null)) {
      alert(t`Minimum amount 100`);
      return
    }
    if (!das.id_pool1 || das.id_pool1 == das.id_pool) {
      alert(t`Выбран неправильный Pool `);
      return
    }
    if (!isekod) {
      setIsekod(true)
      let s_key = localStorage.getItem("s_key");
      axios.post(url_serversq + "/sendkey1.php", {
        s_key: s_key
      }).then(res => {
        if (res.data !== false) {
        } else {
          alert("An error occurred on the server");
        }
      }).catch(() => {
        alert("An error occurred on the server");
      })
    }
  };

  const handleSave = (event) => {
    //console.log(3);
    event.preventDefault();

    if ((parseFloat(amount)) < 100 && (das['balanceinpool' + getPool(das.id_pool)] == 0 || das['balanceinpool' + getPool(das.id_pool)] == null)) {
      alert(t`Minimum amount 100`);
      return
    }

    if (isekod) {
      let s_key = localStorage.getItem("s_key");
      let reqa = { ekod: ekod, amount: amount, oper: 3, id_pool: das.id_pool, id_pool1: das.id_pool1, s_key: s_key }
      //console.log(reqa);
      axios.post(url_serversq + "/reqpool.php", reqa).then(res => {
        if (res.data !== false) {
          alert(res.data);
          setDas(false);
        } else {
          alert(t`Ошибка добавления, неправильно введенные данные`);
          setDas(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setDas(false);
      })
    }

  };
  const handleChange = (event) => {
    setDas(prev => { return { ...prev, id_pool1: event.target.value } });

  };
  return (
    <Card >
      <Grid container p={0} >
        <Grid item xs={1} md={3} >

        </Grid>
        <Grid item xs={10} md={6}>
          <MDTypography variant="h4" mt={4} textAlign="center">{t`Заявка на перевод средств с Pool ` + getPool(das.id_pool)}</MDTypography>
          <Card style={{ marginTop: "15px", marginBottom: "15px", padding: "25px" }} >

            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={6} >
                <MDTypography variant="h6" textAlign="end">{t`Баланс в Pool ` + getPool(das.id_pool)}</MDTypography>
              </Grid>
              <Grid item xs={6} >
                <MDInput type="text" fullWidth value={das['balanceinpool' + getPool(das.id_pool)]} disable={true} />
              </Grid>

              <Grid item xs={4} >
                <MDTypography variant="h6" textAlign="end">{t`Перенести в`}</MDTypography>
              </Grid>
              <Grid item xs={4} >
                <FormControl sx={{ minWidth: '100%' }}>
                  <InputLabel id="pool-select-label">{t`Pool SN`}</InputLabel>
                  <Select
                    sx={{ minHeight: '3em' }}
                    labelId="pool-select-label"
                    value={das.id_pool1}
                    label={t`Pool SN`}
                    onChange={handleChange}
                    autoWidth
                  >
                    <MenuItem key={"pl2"} value={2}>{'Pool X1'}</MenuItem>
                    <MenuItem key={"pl1"} value={1}>{'Pool X2'}</MenuItem>
                    <MenuItem key={"pl3"} value={3}>{'Pool X3'}</MenuItem>
                    <MenuItem key={"plM"} value={4}>{'Pool MIXT'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <MDInput type="text" fullWidth value={amount} onChange={(event) => { if (!isekod) setAmount(event.target.value) }} />
              </Grid>

              <Grid item xs={4} >
                <MDTypography variant="h6" textAlign="end">{t`E-Mail код`}</MDTypography>
              </Grid>
              <Grid item xs={4} >
                <MDInput type="text" fullWidth value={ekod} onChange={(event) => { if (isekod) setEkod(event.target.value) }} />
              </Grid>
              <Grid item xs={4} >
                {!isekod ? <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={handleSend} >{t`получить`} </MDButton> : null}
              </Grid>

              <Grid item xs={6} textAlign="center">
                <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={() => setDas(prev => { return { ...prev, oper: 0, id_pool: 0 } })} >{t`отмена`} </MDButton>
              </Grid>
              <Grid item xs={6} textAlign="center">
                {isekod ? <MDButton variant="gradient" color="info" style={{ margin: "5px" }} onClick={handleSave} >{t`сохранить`} </MDButton> : null}
              </Grid>

            </Grid>
          </Card>
        </Grid >
        <Grid item xs={1} md={3}>

        </Grid>
      </Grid >


    </Card >

  );


}

export default MoveDep;
