//const url_serversq = "http://mail.stimul.dp.ua:40583/srv";
//const url_serversq = "http://localhost/srv";
var url_serversq = document.location.protocol + "//" + document.location.host + "/srv";
var sitekey = "6LcSprklAAAAAAlykTiXsueXEW6YW4VLHn_CNx-0";

if (document.location.host == "localhost:3000") {
  url_serversq = "http://localhost/srv";
}

function number_format(number, decimals, dec_point, thousands_sep) {  // Format a number with grouped thousands
  var i, j, kw, kd, km, zn;
  zn = "";
  if (number < 0)
    zn = "-";
  if (isNaN(decimals = Math.abs(decimals))) {
    decimals = 2;
  }
  if (dec_point == undefined) {
    dec_point = ",";
  }
  if (thousands_sep == undefined) {
    thousands_sep = ".";
  }

  i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

  if ((j = i.length) > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  km = (j ? i.substr(0, j) + thousands_sep : "");
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
  kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

  return zn + km + kw + kd;
}

function getPool(id_pool) {  // Format a number with grouped thousands
  var res;
  if (id_pool == 1) {
    res = 'X2';
  }
  if (id_pool == 2) {
    res = 'X1';
  }
  if (id_pool == 3) {
    res = 'X3';
  }
  if (id_pool == 4) {
    res = 'Mixt';
  }
  return res;
}

export {
  url_serversq,
  sitekey,
  number_format,
  getPool,
};
