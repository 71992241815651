/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import YoutubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import colors from "../../assets/theme/base/colors";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "../../assets/theme/base/typography";
import { t } from "ttag";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;
  const { socialMediaColors } = colors;
  /*  const renderLinks = () =>
     links.map((link) => (
       <MDBox key={link.name} component="li" px={2} lineHeight={1}>
         <Link href={link.href} target="_blank">
           <MDTypography variant="button" fontWeight="regular" color="text">
             {link.name}
           </MDTypography>
         </Link>
       </MDBox>
     ));
  */
  var social =
    [
      {
        link: "https://www.youtube.com/channel/UC5DYtGxntz91khBVIzcEdyw",
        icon: <YoutubeIcon />,
        color: "youtube",
      },
      {
        link: "https://t.me/+kefuD3Vf0X1kY2Ji",
        icon: <TelegramIcon />,
        color: "telegram",
      },

    ];

  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ));
  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      mt={3}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;{name}&nbsp;
          </MDTypography>
        </Link>
        {t`Все права защищены.`}
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <MDBox key="k1" display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Email: &nbsp;
          </MDTypography>
          <MDTypography component="a"
            href="mailto:SoNatTrade@gmail.com" variant="button" fontWeight="regular" color="text">
            &nbsp;SoNatTrade@gmail.com
          </MDTypography>
        </MDBox>
        <MDBox key="k2" display="flex" py={1} pr={2}>
          <MDTypography component="a"
            href="https://t.me/SoNat7777" variant="button" fontWeight="regular" color="text">
            &nbsp;Telegram channel
          </MDTypography>
        </MDBox>



        {renderSocial}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "/", name: "SoNat" },
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
